class CookieManager {
    getDataFromLocalStorage = (key = null) => {
        const data = JSON.parse(localStorage.getItem(key));
        this.data = data;
        return this.data;
    };

    setDataToLocalStorage = (key = null, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    };

    clearStorage = () => {
        localStorage.clear();
    };
}

export default new CookieManager();
