import React from 'react';
import { SvgIcon } from '@mui/material';

const LargeDownloadIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 40 40" {...props}>
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M35.8,20.4v9.8c0,1.3-0.3,1.5-1.5,1.5H5.7c-1.4,0-1.5-0.1-1.5-1.7c-0.1-3.1,0-6.2,0-9.4"
            />
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M13.9,20.1l6.1,6.4V8.3"
            />
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M26.2,19.7L20,26.6"
            />
        </SvgIcon>
    );
};

export default LargeDownloadIcon;
