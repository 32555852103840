import React, { useEffect, useState, useRef } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { Line } from 'react-chartjs-2';
import validate from 'validate.js';
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles, useTheme } from '@mui/styles';

import { 
    useMediaQuery,
    Button,
    CircularProgress,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material';

// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker
// } from '@material-ui/pickers';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ArrowLeftIcon from './components/ArrowLeftIcon';
import ArrowRightIcon from './components/ArrowRightIcon';
import CalendarIcon from './components/CalendarIcon';
import DownloadIcon from './components/DownloadIcon';
import GraphIcon from './components/GraphIcon';
import LargeDownloadIcon from './components/LargeDownloadIcon';
import TableIcon from './components/TableIcon';
import RouterImage from './images/icon-router.svg';
import PrimaryRouterImage from './images/icon-router-primary.svg';
import TouchMoveIcon from './images/icon-touch-move.png';
import { axiosInstance } from '../../helpers/axiosInstance';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
const dateFnsUtils = new DateFnsUtils();

const resultsGridCustomBreakpointWidth = '(max-width: 480px)';
const resultsGridCustomBreakpoint = `@media ${resultsGridCustomBreakpointWidth}`;
const graphHeightCustomBreakpoint = '@media (min-height: 920px)';

const sectionSidePadding = 30;
const sectionSidePaddingSmUp = 38;
const uptimePercentageColor = '#95e82c';
const pageSize = 10;
var min = 80;

const chartOptions = {
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    padding: 20,
                    labelString: 'Date',
                    fontSize: 20,
                    fontStyle: 'bold'
                },
                barPercentage: '0.16'
            }
        ],
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    padding: 20,
                    labelString: 'Router Uptime (%)',
                    fontSize: 20,
                    fontStyle: 'bold'
                },
                ticks: {
                    callback: (percentage) => `${formatNumber(percentage)}`,
                    max: 100,
                    //suggestedMin:min,
                    //stepSize:20,
                    maxTicksLimit: 5
                }
            }
        ]
    },
    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
            label: ({ datasetIndex, yLabel }, { datasets }) => {
                return `${datasets[datasetIndex].label}: ${formatNumber(yLabel, 2)}`;
            }
        }
    }
};

const schema = {
    routerNameId: {
        presence: {
            allowEmpty: false,
            message: '^Router name / ID is required'
        }
    },
    fromDate: {
        presence: { allowEmpty: false, message: '^From Date is required' }
    },
    toDate: {
        presence: { allowEmpty: false, message: '^To Date is required' }
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white
    },

    section: {
        padding: `30px ${sectionSidePadding}px`,
        backgroundColor: '#f8f8f8',

        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(5)}px ${sectionSidePaddingSmUp}px`
        }
    },

    altSection: {
        backgroundColor: theme.palette.white
    },

    altSectionFlex: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    sectionCenterContent: {
        textAlign: 'center'
    },

    backLink: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            display: 'block',
            fontSize: 14
        }
    },

    filters: {
        marginTop: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginLeft: -theme.spacing(3)
        }
    },

    filterName: {
        [theme.breakpoints.up('md')]: {
            width: `calc(50% - ${(130 + theme.spacing(3)) / 2}px)`,
            paddingLeft: theme.spacing(3)
        }
    },

    filterDates: {
        display: 'flex',
        marginTop: theme.spacing(1),

        [theme.breakpoints.up('md')]: {
            width: `calc(50% - ${(130 + theme.spacing(3)) / 2}px)`,
            marginTop: 0,
            paddingLeft: theme.spacing(3)
        }
    },

    filterDate: {
        width: '50%'
    },

    filterDateField: {
        '& .MuiInputAdornment-positionStart': {
            marginRight: 0
        }
    },

    firstFilterDateField: {
        '&:hover': {
            zIndex: 1
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    },

    secondFilterDateField: {
        '&:hover': {
            zIndex: 1
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            left: -1
        }
    },

    filterCta: {
        width: 130,
        marginTop: theme.spacing(1),

        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            width: 130 + theme.spacing(3),
            paddingLeft: theme.spacing(3)
        }
    },

    filterCtaButton: {
        width: '100%',
        textTransform: 'none'
    },

    routerImage: {
        width: 140,
        height: 140
    },

    resultsHeading: {
        fontSize: 26,
        letterSpacing: 0.15,
        lineHeight: 1,
        color: theme.palette.primary.main,

        '&:after': {
            content: '""',
            display: 'block',
            width: 50,
            height: 5,
            marginTop: theme.spacing(1),
            backgroundColor: theme.palette.primary.main
        }
    },

    deviceNameGridItem: {
        order: 1,

        [theme.breakpoints.up('sm')]: {
            order: 2
        },

        [`${resultsGridCustomBreakpoint}`]: {
            maxWidth: '100%',
            flexBasis: '100%'
        }
    },

    deviceNameHeading: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14
        }
    },

    deviceNameIcon: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(2),
        verticalAlign: 'middle'
    },

    mobileActionsGridItem: {
        textAlign: 'right',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },

    iconButton: {
        width: 40,
        minWidth: 40,
        height: 40,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },

    largeIconButton: {
        width: 60,
        minWidth: 60,
        height: 60,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        '& svg': {
            width: 40,
            height: 40
        }
    },

    resultsActionsGridItem: {
        order: 2,

        [theme.breakpoints.up('sm')]: {
            order: 1
        },

        [`${resultsGridCustomBreakpoint}`]: {
            maxWidth: '100%',
            flexBasis: '100%'
        }
    },

    resultsActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: -theme.spacing(1),

        [`${resultsGridCustomBreakpoint}`]: {
            justifyContent: 'flex-start',
            marginLeft: -theme.spacing(1)
        }
    },

    resultsAction: {
        marginRight: theme.spacing(1),
        textTransform: 'none',

        [`${resultsGridCustomBreakpoint}`]: {
            marginLeft: theme.spacing(1)
        }
    },

    resultsDesktopAction: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },

    downloadReport: {
        border: '1px solid #707070',
        borderRadius: 5,
        padding: theme.spacing(6, 3),
        marginTop: theme.spacing(5),
        textAlign: 'center'
    },

    downloadReportHeader: {
        marginBottom: theme.spacing(1)
    },

    downloadReportCta: {
        marginTop: theme.spacing(2)
    },

    table: {
        marginTop: theme.spacing(5)
    },

    tableCell: {
        width: '50%'
    },

    pagination: {
        marginRight: -theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    },

    paginationButton: {
        width: 35,
        height: 35,
        minWidth: 35,
        padding: '3px 8px',
        marginRight: theme.spacing(1)
    },

    icon: {
        color: 'transparent'
    },

    graph: {
        marginTop: theme.spacing(5)
    },

    graphScrollable: {
        marginBottom: theme.spacing(3),
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            padding: theme.spacing(3),
            fontSize: 20
        },

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },

    graphScrollableIcon: {
        marginRight: theme.spacing(2)
    },

    graphScrollableIconImage: {
        verticalAlign: 'middle'
    },

    graphHeader: {
        fontSize: 14,
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            fontSize: 16
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 20
        }
    },

    graphHeading: {
        fontWeight: 'bold'
    },

    graphLegend: {
        overflow: 'hidden'
    },

    graphLegendInner: {
        marginTop: theme.spacing(3),
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(5),
            marginLeft: -theme.spacing(3),
            marginRight: -theme.spacing(3)
        },

        [theme.breakpoints.up('md')]: {
            marginLeft: -theme.spacing(5),
            marginRight: -theme.spacing(5)
        }
    },

    graphLegendItem: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        },

        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5)
        }
    },

    graphLegendLine: {
        width: 30,
        height: 30,
        marginRight: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            width: 50
        }
    },

    graphArea: {
        margin: `${theme.spacing(3)}px -${sectionSidePadding}px 0`,
        overflow: 'auto',

        [theme.breakpoints.up('sm')]: {
            margin: `${theme.spacing(5)}px -${sectionSidePaddingSmUp}px 0`
        }
    },

    graphAreaInner: {
        height: '60vh',
        position: 'relative',
        overflow: 'hidden',

        [`${graphHeightCustomBreakpoint}`]: {
            height: 550
        }
    },

    graphAreaCanvas: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: sectionSidePadding,
        right: sectionSidePadding,

        [theme.breakpoints.up('sm')]: {
            left: sectionSidePaddingSmUp,
            right: sectionSidePaddingSmUp
        }
    }
}));

const formatNumber = (number, decimals = 0, separator = ',') => {
    return Number(number)
        .toFixed(decimals)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator);
};

const makeChartData = (routerUptimeReportData, timeSelect) => ({
    labels: routerUptimeReportData.map((data) =>
        timeSelect === 'month'
            ? moment(data.Month, 'MM').format(`MMM ${data.Year}`)
            : moment(data.Date).format('DD MMM YYYY')
    ),
    datasets: [
        {
            label: 'Router Uptime',
            fill: false,
            lineTension: 0,
            borderColor: uptimePercentageColor,
            borderWidth: 1,
            backgroundColor: uptimePercentageColor,
            pointBorderWidth: 0,
            pointHoverBackgroundColor: uptimePercentageColor,
            pointRadius: 5,
            data: routerUptimeReportData.map((data) => data.uptimePercentage)
        }
    ]
});

const makeChartOptions = (routerUptimeData, options) => {
    min = 100;
    routerUptimeData.map((data) => {
        min = Math.min(min, data.uptimePercentage);
    });
    min = min % 20 === 0 ? min - 20 : min;
    options.scales.yAxes[0].ticks.suggestedMin = Math.floor(min / 20) * 20;
    return options;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RouterUptime = (props) => {
    let query = useQuery();
    let name = query.get('name');
    let startDate = query.get('startDate');
    let endDate = query.get('endDate');

    const classes = useStyles();
    const theme = useTheme();
    const { promiseInProgress: reportPromiseInProgress } = usePromiseTracker({
        area: 'report'
    });
    const { promiseInProgress: routerPromiseInProgress } = usePromiseTracker({
        area: 'routerList'
    });
    const [filterFormState, setFilterFormState] = useState({
        values: {
            routerNameId: name ? { customersDeviceName: name } : '',
            fromDate: startDate
                ? moment(startDate, 'YYYY-MM-DD-HH-mm-ss').toISOString()
                : null,
            toDate: endDate
                ? moment(endDate, 'YYYY-MM-DD-HH-mm-ss').toISOString()
                : null
        },
        isValid: false,
        touched: {},
        errors: {}
    });
    const [filter, setFilter] = useState({
        ...filterFormState.values,
        routerNameId: filterFormState.values.routerNameId.customersDeviceName
            ? filterFormState.values.routerNameId.customersDeviceName
            : undefined
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [routerUptimeReportData, setRouterUptimeReportData] = useState();
    const [resultsView, setResultsView] = useState('graph');
    const [timeSelect, setTimeSelect] = useState('month');
    const [routerList, setRouterList] = useState([]);
    const smallestMediaQuery = useMediaQuery(resultsGridCustomBreakpointWidth);
    const smUpMediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
    const hasError = (field) =>
        filterFormState.touched[field] && filterFormState.errors[field]
            ? true
            : false;
    const [graphIsScrollable, setGraphIsScrollable] = useState(false);
    const graphAreaRef = useRef(null);

    const checkGraphIsScrollable = () => {
        return (
            graphAreaRef.current &&
            graphAreaRef.current.scrollWidth > graphAreaRef.current.clientWidth
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setGraphIsScrollable(checkGraphIsScrollable());
        };

        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        trackPromise(
            axiosInstance
                .get('getRouterDevicesList')
                .then((response) => {
                    setRouterList(response.data.data.result);
                })
                .catch((err) => {
                    console.log(err);
                }),
            'routerList'
        );
    }, []);

    useEffect(() => {
        const errors = validate(filterFormState.values, schema);

        setFilterFormState((filterFormState) => ({
            ...filterFormState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [filterFormState.values]);

    useEffect(() => {
        const { routerNameId, fromDate, toDate } = filter;

        if (routerNameId && fromDate && toDate) {
            let endpoint = '';
            if (timeSelect === 'month') {
                endpoint = 'getRouterMonthlyUptimePercentage';
            } else if (timeSelect === 'day') {
                endpoint = 'getRouterDailyUptimePercentage';
            }

            trackPromise(
                axiosInstance
                    .post(endpoint, filter)
                    .then(updateRouterUptimeReportData)
                    .catch((error) => {
                        console.log(error);
                    }),
                'report'
            );
        } else {
            setRouterUptimeReportData(undefined);
        }
    }, [filter, timeSelect]);

    useEffect(() => {
        setTimeout(() => {
            setGraphIsScrollable(checkGraphIsScrollable());
        }, 0);
    }, [routerUptimeReportData, currentPage, totalPages, resultsView]);

    const updateRouterUptimeReportData = (response) => {
        setRouterUptimeReportData(response.data.data);
        setCurrentPage(0);
        setTotalPages(Math.ceil(response.data.data.length / pageSize));
    };

    const handleFilterFormSubmit = (e) => {
        e.preventDefault();

        if (filterFormState.isValid) {
            setFilter({
                fromDate: filterFormState.values.fromDate,
                toDate: filterFormState.values.toDate,
                routerNameId:
                    filterFormState.values.routerNameId.customersDeviceName
            });
        } else {
            // If still invalid, make the form errors visible.
            const errors = validate(filterFormState.values, schema);
            let newTouched = {};

            Object.keys(filterFormState.values).forEach((name) => {
                newTouched[name] = true;
            });

            setFilterFormState((filterFormState) => ({
                ...filterFormState,
                isValid: errors ? false : true,
                errors: errors || {},
                touched: {
                    ...filterFormState.touched,
                    ...newTouched
                }
            }));
        }
    };

    const handleFormRouterNameChange = (e, value) => {
        setFilterFormState((filterFormState) => ({
            ...filterFormState,
            values: {
                ...filterFormState.values,
                routerNameId: value ? value : ''
            },
            touched: {
                ...filterFormState.touched,
                routerNameId: true
            }
        }));
    };

    /*const handleFilterFormInputChange = (e) => {
    e.persist();
    
    setFilterFormState(filterFormState => ({
      ...filterFormState,
      values: {
        ...filterFormState.values,
        [e.target.name]:
          e.target.type === 'checkbox'
            ? e.target.checked
            : e.target.value
      },
      touched: {
        ...filterFormState.touched,
        [e.target.name]: true
      }
    }));
  };*/

    const handleFilterFromDateChange = (e) => {
        setFilterFormState((filterFormState) => ({
            ...filterFormState,
            values: {
                ...filterFormState.values,
                fromDate: new Date(e.setHours(0, 0, 0))
            },
            touched: {
                ...filterFormState.touched,
                fromDate: true
            }
        }));
        console.log(filterFormState);
    };

    const handleFilterToDateChange = (e) => {
        setFilterFormState((filterFormState) => ({
            ...filterFormState,
            values: {
                ...filterFormState.values,
                toDate: new Date(e.setHours(23, 59, 59))
            },
            touched: {
                ...filterFormState.touched,
                toDate: true
            }
        }));
    };

    const handleShowGraphClick = () => {
        setResultsView('graph');
    };

    const handleShowTableClick = () => {
        setResultsView('table');
    };

    const handlePaginationButtonClick = (index) => {
        setCurrentPage(index);
    };

    const handlePaginationPreviousButtonClick = () => {
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handlePaginationNextButtonClick = () => {
        setCurrentPage(Math.min(totalPages - 1, currentPage + 1));
    };

    const handleShowMonthClick = () => {
        setTimeSelect('month');
    };

    const handleShowDayClick = () => {
        setTimeSelect('day');
    };

    const getQuickChart = async () => {
        let body = {
            chart: {
                type: 'line',
                data: makeChartData(routerUptimeReportData, timeSelect),
                options: chartOptions
            },
            height: 350,
            width: 800,
            backgroundColor: 'white',
            format: 'jpg'
        };

        await axiosInstance
            .post('getQuickChart', body)
            .then((data) => {
                let pdf = new jsPDF('l');
                pdf.text(20, 20, routerUptimeReportData[0].customersDeviceName);
                // let imgData = graphUrl.toString('base64');
                pdf.addImage(data.data.data, 'JPEG', 5, 50, 285, 110); //For landscape
                //pdf.addImage(data.data.data,'JPEG', 0,0,210,170);
                var tableData = [];
                tableData.push(
                    routerUptimeReportData.map((row) => {
                        return [
                            timeSelect === 'month'
                                ? moment(row.Month, 'MM').format(
                                      `MMM ${row.Year}`
                                  )
                                : moment(row.Date).format('DD MMM YYYY'),
                            row.uptimePercentage
                        ];
                    })
                );
                pdf.addPage();
                pdf.autoTable({
                    headStyles: { fillColor: [18, 137, 88] },
                    head: [['Date', 'Uptime Percentage']],
                    body: tableData[0]
                });
                pdf.save(
                    `Dashboard_${routerUptimeReportData[0].customersDeviceName}_${moment().format('YYYYMMDD-HHmm')}.pdf`
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDownloadReportClick = async () => {
        await getQuickChart();
    };

    const useAltSectionFlexClass = () => {
        return (
            reportPromiseInProgress ||
            !routerUptimeReportData ||
            routerUptimeReportData.length === 0
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.backLink}>
                    <Link href="./dashboard" color="inherit">
                        Home
                    </Link>
                    &nbsp; &gt; &nbsp;<b>Router Uptime Report</b>
                </div>

                <div className={classes.sectionHeader}>
                    <Typography variant="h2" component="h1">
                        Router Uptime Report
                    </Typography>
                </div>

                <form
                    className={classes.filters}
                    onSubmit={handleFilterFormSubmit}
                >
                    <div className={classes.filterName}>
                        <Autocomplete
                            value={filterFormState.values.routerNameId}
                            name="routerNameId"
                            id="routerNameId"
                            options={routerList}
                            onChange={handleFormRouterNameChange}
                            getOptionSelected={(option, value) => {
                                return (
                                    option.customersDeviceName ===
                                    value.customersDeviceName
                                );
                            }}
                            loading={routerPromiseInProgress}
                            getOptionLabel={(option) =>
                                option.customersDeviceName
                                    ? option.customersDeviceName
                                    : ''
                            }
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <TextField
                                        fullWidth
                                        error={hasError('routerNameId')}
                                        helperText={
                                            hasError('routerNameId')
                                                ? filterFormState.errors
                                                      .routerNameId[0]
                                                : null
                                        }
                                        placeholder="Router name / ID"
                                        type="text"
                                        variant="outlined"
                                        {...params.inputProps}
                                    />
                                </div>
                            )}
                        />
                    </div>

                    <div className={classes.filterDates}>
                        <LocalizationProvider>
                            <div className={classes.filterDate}>
                                <DatePicker
                                    fullWidth
                                    error={hasError('fromDate')}
                                    helperText={
                                        hasError('fromDate')
                                            ? filterFormState.errors.fromDate[0]
                                            : null
                                    }
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="fromDate"
                                    placeholder="From Date"
                                    value={filterFormState.values.fromDate}
                                    onChange={handleFilterFromDateChange}
                                    inputVariant="outlined"
                                    InputAdornmentProps={{
                                        position: 'start'
                                    }}
                                    KeyboardButtonProps={{
                                        edge: 'start',
                                        'aria-label': 'change date'
                                    }}
                                    disableFuture
                                    keyboardIcon={
                                        <CalendarIcon
                                            className={classes.icon}
                                        />
                                    }
                                    className={`${classes.filterDateField} ${classes.firstFilterDateField}`}
                                />
                            </div>

                            <div className={classes.filterDate}>
                                <DatePicker
                                    fullWidth
                                    error={hasError('toDate')}
                                    helperText={
                                        hasError('toDate')
                                            ? filterFormState.errors.toDate[0]
                                            : null
                                    }
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="toDate"
                                    placeholder="To Date"
                                    value={filterFormState.values.toDate}
                                    onChange={handleFilterToDateChange}
                                    inputVariant="outlined"
                                    InputAdornmentProps={{
                                        position: 'start'
                                    }}
                                    KeyboardButtonProps={{
                                        edge: 'start',
                                        'aria-label': 'change date'
                                    }}
                                    disableFuture
                                    keyboardIcon={
                                        <CalendarIcon
                                            className={classes.icon}
                                        />
                                    }
                                    className={`${classes.filterDateField} ${classes.secondFilterDateField}`}
                                />
                            </div>
                        </LocalizationProvider>
                    </div>

                    <div className={classes.filterCta}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            className={classes.filterCtaButton}
                        >
                            Generate
                        </Button>
                    </div>
                </form>
            </div>

            <div
                className={`${classes.section} ${classes.altSection} ${useAltSectionFlexClass() ? classes.altSectionFlex : ''}`}
            >
                {reportPromiseInProgress && (
                    <div className={classes.sectionCenterContent}>
                        <CircularProgress />
                    </div>
                )}

                {!reportPromiseInProgress &&
                    routerUptimeReportData &&
                    routerUptimeReportData.length !== 0 && (
                        <>
                            <Grid
                                container
                                spacing={smallestMediaQuery ? 2 : 4}
                            >
                                <Grid item xs={6} sm={5} md={4}>
                                    <Typography
                                        variant="h2"
                                        className={classes.resultsHeading}
                                    >
                                        Results
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    sm={12}
                                    className={classes.deviceNameGridItem}
                                >
                                    <Typography
                                        variant="h3"
                                        className={classes.deviceNameHeading}
                                    >
                                        <img
                                            src={PrimaryRouterImage}
                                            alt=""
                                            className={classes.deviceNameIcon}
                                        />
                                        {
                                            routerUptimeReportData[0]
                                                .customersDeviceName
                                        }
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    sm={7}
                                    md={8}
                                    className={classes.mobileActionsGridItem}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.iconButton}
                                        style={{
                                            display:
                                                resultsView === 'table'
                                                    ? 'none'
                                                    : undefined
                                        }}
                                    >
                                        <DownloadIcon
                                            className={classes.icon}
                                        />
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    sm={7}
                                    md={8}
                                    className={classes.resultsActionsGridItem}
                                >
                                    <div className={classes.resultsActions}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={
                                                <DownloadIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            size="large"
                                            className={`${classes.resultsAction} ${classes.resultsDesktopAction}`}
                                            onClick={handleDownloadReportClick}
                                        >
                                            Download
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={
                                                <CalendarIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            size="large"
                                            className={classes.resultsAction}
                                            onClick={
                                                timeSelect === 'day'
                                                    ? handleShowMonthClick
                                                    : handleShowDayClick
                                            }
                                        >
                                            {timeSelect === 'day'
                                                ? 'Switch to Month'
                                                : 'Switch to Day'}
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={
                                                <GraphIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            size="large"
                                            className={classes.resultsAction}
                                            onClick={handleShowGraphClick}
                                            style={{
                                                display:
                                                    resultsView === 'graph'
                                                        ? 'none'
                                                        : undefined
                                            }}
                                        >
                                            Show Graph
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={
                                                <TableIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            size="large"
                                            className={classes.resultsAction}
                                            onClick={handleShowTableClick}
                                            style={{
                                                display:
                                                    resultsView === 'table'
                                                        ? 'none'
                                                        : undefined
                                            }}
                                        >
                                            Show Table
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                            {resultsView === 'table' && !smUpMediaQuery && (
                                <div className={classes.downloadReport}>
                                    <Typography
                                        variant="h3"
                                        className={classes.downloadReportHeader}
                                    >
                                        Download Report
                                    </Typography>

                                    <Typography variant="body1">
                                        The report can only be downloaded for
                                        viewing.
                                        <br />
                                        For full viewing, please use a PC.
                                    </Typography>

                                    <div className={classes.downloadReportCta}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.largeIconButton}
                                            onClick={handleDownloadReportClick}
                                        >
                                            <LargeDownloadIcon
                                                className={classes.icon}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {resultsView === 'table' && smUpMediaQuery && (
                                <TableContainer
                                    className={classes.table}
                                    id="table"
                                >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Date
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Router Uptime (%)
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {[...routerUptimeReportData]
                                                .reverse()
                                                .slice(
                                                    currentPage * pageSize,
                                                    (currentPage + 1) * pageSize
                                                )
                                                .map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {timeSelect ===
                                                            'month'
                                                                ? moment(
                                                                      row.Month,
                                                                      'MM'
                                                                  ).format(
                                                                      `MMM ${row.Year}`
                                                                  )
                                                                : moment(
                                                                      row.Date
                                                                  ).format(
                                                                      'DD MMM YYYY'
                                                                  )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatNumber(
                                                                row.uptimePercentage,
                                                                2
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    Showing{' '}
                                                    {currentPage * pageSize + 1}
                                                    -
                                                    {Math.min(
                                                        (currentPage + 1) *
                                                            pageSize,
                                                        routerUptimeReportData.length
                                                    )}{' '}
                                                    out of{' '}
                                                    {
                                                        routerUptimeReportData.length
                                                    }
                                                </TableCell>

                                                <TableCell colSpan="2">
                                                    {totalPages > 1 && (
                                                        <div
                                                            className={
                                                                classes.pagination
                                                            }
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                size="large"
                                                                className={
                                                                    classes.paginationButton
                                                                }
                                                                onClick={
                                                                    handlePaginationPreviousButtonClick
                                                                }
                                                            >
                                                                <ArrowLeftIcon
                                                                    className={
                                                                        classes.icon
                                                                    }
                                                                />
                                                            </Button>

                                                            {Array(totalPages)
                                                                .fill(undefined)
                                                                .map(
                                                                    (
                                                                        val,
                                                                        index
                                                                    ) => (
                                                                        <Button
                                                                            variant={
                                                                                index ===
                                                                                currentPage
                                                                                    ? 'contained'
                                                                                    : 'outlined'
                                                                            }
                                                                            color={
                                                                                index ===
                                                                                currentPage
                                                                                    ? 'primary'
                                                                                    : undefined
                                                                            }
                                                                            size="large"
                                                                            className={
                                                                                classes.paginationButton
                                                                            }
                                                                            onClick={() => {
                                                                                handlePaginationButtonClick(
                                                                                    index
                                                                                );
                                                                            }}
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {index +
                                                                                1}
                                                                        </Button>
                                                                    )
                                                                )}

                                                            <Button
                                                                variant="outlined"
                                                                size="large"
                                                                className={`${classes.paginationButton} ${classes.paginationIconButton}`}
                                                                onClick={
                                                                    handlePaginationNextButtonClick
                                                                }
                                                            >
                                                                <ArrowRightIcon
                                                                    className={
                                                                        classes.icon
                                                                    }
                                                                />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            )}

                            {resultsView === 'graph' && (
                                <div className={classes.graph} id="graph">
                                    {graphIsScrollable && (
                                        <div
                                            className={classes.graphScrollable}
                                        >
                                            <div
                                                className={
                                                    classes.graphScrollableIcon
                                                }
                                            >
                                                <img
                                                    src={TouchMoveIcon}
                                                    alt=""
                                                    className={
                                                        classes.graphScrollableIconImage
                                                    }
                                                />
                                            </div>

                                            <div>
                                                Please scroll left/right to
                                                <br />
                                                browse the graph.
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes.graphHeader}>
                                        <div className={classes.graphHeading}>
                                            Router Uptime (%) (
                                            {timeSelect === 'day'
                                                ? `${dateFnsUtils.format(new Date(filter.fromDate), 'dd/MM/yyyy')} - ${dateFnsUtils.format(new Date(filter.toDate), 'dd/MM/yyyy')}`
                                                : `${moment(filter.fromDate).format('MMM YYYY')} - ${moment(filter.toDate).format('MMM YYYY')}`}
                                            )
                                        </div>

                                        <div className={classes.graphLegend}>
                                            <div
                                                className={
                                                    classes.graphLegendInner
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.graphLegendItem
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            classes.graphLegendLine
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                uptimePercentageColor
                                                        }}
                                                    />
                                                    Router Uptime %
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={classes.graphArea}
                                        ref={graphAreaRef}
                                    >
                                        <div
                                            className={classes.graphAreaInner}
                                            style={{
                                                minWidth:
                                                    routerUptimeReportData.length *
                                                    60
                                            }}
                                        >
                                            <div
                                                className={
                                                    classes.graphAreaCanvas
                                                }
                                            >
                                                <Line
                                                    data={makeChartData(
                                                        routerUptimeReportData,
                                                        timeSelect
                                                    )}
                                                    options={makeChartOptions(
                                                        routerUptimeReportData,
                                                        chartOptions
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                {!reportPromiseInProgress &&
                    routerUptimeReportData &&
                    routerUptimeReportData.length === 0 && (
                        <div className={classes.sectionCenterContent}>
                            <img
                                src={RouterImage}
                                alt=""
                                className={classes.routerImage}
                            />

                            <Typography variant="body1">
                                No data available with the parameters above.
                            </Typography>
                        </div>
                    )}

                {!reportPromiseInProgress && !routerUptimeReportData && (
                    <div className={classes.sectionCenterContent}>
                        <img
                            src={RouterImage}
                            alt=""
                            className={classes.routerImage}
                        />

                        <Typography variant="body1">
                            Please set the parameters above to generate or
                            download a report.
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RouterUptime;
