export default {
    root: {
        '& tr': {
            transition: '0.4s background-color cubic-bezier(0.19, 1, 0.22, 1)',

            '&:hover': {
                backgroundColor: '#f8f8f8'
            }
        }
    }
};
