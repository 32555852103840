import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export const uptimeColumns = [
    {
        field: 'id',
        headerName: 'No',
        maxWidth: 100,
        flex: 1
    },
    {
        field: 'Name',
        headerName: 'Router name',
        minWidth: 200,
        flex: 1
    },
    {
        field: 'Date',
        headerName: 'Date',
        minWidth: 100,
        flex: 1
    },
    {
        field: 'uptimePercentage',
        headerName: 'Uptime Percentage',
        minWidth: 100,
        flex: 1
    }
];

export const deviceUsageColumns = [
    {
        field: 'id',
        headerName: 'No',
        maxWidth: 60,
        flex: 1
    },
    {
        field: 'Name',
        headerName: 'Router name',
        // width: 130,
        editable: true,
        flex: 2.5
    },
    {
        field: 'TxBytes',
        headerName: 'TX MB',
        // width: 100,
        editable: true,
        flex: 1
    },
    {
        field: 'RxBytes',
        headerName: 'RX MB',
        // type: 'number',
        // width: 100,
        editable: true,
        flex: 1
    },
    {
        field: 'TotalBytes',
        headerName: 'Total MB',
        // type: 'number',
        // width: 110,
        editable: true,
        flex: 1
    },
    {
        field: 'Date',
        headerName: 'Date',
        editable: true,
        flex: 1,
        // width: 150
    },
    // {
    //     field: 'MSISDN',
    //     headerName: 'MSISDN',
    //     editable: true,
    //     width: 100,
    //     flex: 1
    // },
    // {
    //     field: 'SimNo',
    //     headerName: 'SIM No',
    //     editable: true,
    //     width: 100
    // },
    // {
    //     field: 'SimIp',
    //     headerName: 'SIM IP',
    //     editable: true,
    //     width: 100
    // },
    // {
    //     field: 'RatePlan',
    //     headerName: 'Rate Plan',
    //     editable: true,
    //     width: 100
    // },
    // {
    //     field: 'CurDataUtil',
    //     headerName: 'Current Data Utilization',
    //     editable: true,
    //     width: 150
    // },
    // {
    //     field: 'ExpiryDate',
    //     headerName: 'Expiry Date',
    //     editable: true,
    //     width: 120
    // }
];

export default function DataTable(props) {
    // console.log(props, 'propsprops');
    let rows = props.data?.length ? props.data : [];
    const [columns, setColumns] = useState(deviceUsageColumns);
    useEffect(() => {
        props.tableName == 'router'
            ? setColumns(uptimeColumns)
            : setColumns(deviceUsageColumns);
    }, [props.tableName]);
    // let rows=_rows;
    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                // checkboxSelection
                disableSelectionOnClick
            />
        </div>
    );
}
