import React, { useState, useEffect, useRef } from 'react';

import {
    Container, Select, Grid, Button, FormControl, MenuItem, InputBase, CircularProgress
} from '@mui/material';

import { withStyles } from '@mui/styles';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';

import download from './exportXl';
import { axiosInstance, rbacAxiosInstance } from '../../../helpers/axiosInstance';
import Table from './table';
import Chart from './chart';
import './styles.css';

import { CookieManager } from '../../../helpers';



const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}))(InputBase);

const Reports = () => {
    // const [selectedTab, setSelectedTab] = useState('device');
    const selectedTab = useRef('device');
    const [width, setWidth] = useState(window.innerWidth);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [tableData, setTableData] = useState([]);
    const [viewMode, setViewMode] = useState('table');
    const [routerOptionsList, setRoutersList] = useState([]);
    const [selectedRouter, setSelectedRouter] = useState('All Router');
    const [toggleDailyMonth, setFilterMonth] = useState(false);
    const [chartData, setChartData] = useState({});
    const [loading, setLoading] = useState(false);
    const { promiseInProgress: adminAccessPromiseInProgress } = usePromiseTracker({ area: 'adminAccess' });
    const [companyList, setCompanyList] = useState([]);

    const [companyId, setCompanyId] = useState(-1);

    const [adminAccess, setAdminAccess] = useState(null);

    // first step: check for admin access 
    useEffect(() => {
        
        trackPromise(
            rbacAxiosInstance.post('userHasAccessToModule', {
                system: 'MIN',
                module: 'ADMIN'
            }).then(() => {
                setAdminAccess(true);
                axiosInstance.get('getCompanyList')
                    .then((response) => {
                        // console.log(response.data.data);
                        setCompanyList(response.data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }).catch((err) => {
                if (err?.response) {
                    if (err.response.status === 448) {
                        setAdminAccess(false);
                    }
                }
            }),
            'adminAccess'
        );

        console.log('CookieManager', CookieManager.getDataFromLocalStorage('token'));
    }, []);

    useEffect(() => {
        renderReport();
    }, [toggleDailyMonth, selectedRouter]);

    useEffect(() => {
        getRoutersList();
        renderReport();
    }, [adminAccess, companyId]);

    const handleSelectChangeCompany = (event) => {
        setCompanyId(event.target.value);
        console.log('setCompanyId', event.target.value);
    };

    const renderReport = () => {
        if (selectedTab.current == 'device') {
            renderDeviceUsageReport(selectedRouter, startDate, endDate);
        } else if (selectedTab.current == 'router') {
            renderRouterUptimeReport(selectedRouter, startDate, endDate);
        }
    };

    const handleButtonChangeReport = (event) => {
        selectedTab.current = event.target.value;
        renderReport();
    }

    const formatChartData = (data) => {
        const formatDate = (date) => date.format('DD MMM YYYY');

        const getChartConfig = (label1, label2, dataKey1, dataKey2, color1, color2, factor = 1) => {
            const dataArray1 = [];
            const dataArray2 = [];

            data.forEach((ele) => {
                const value1 = ele[dataKey1] ? (ele[dataKey1] / factor).toFixed(2) : ele[dataKey1];
                const value2 = ele[dataKey2] ? (ele[dataKey2] / factor).toFixed(2) : ele[dataKey2];
                dataArray1.push(value1);
                dataArray2.push(value2);
            });

            return {
                labels: [formatDate(startDate), formatDate(endDate)],
                datasets: [
                    {
                        label: label1,
                        data: dataArray1,
                        fill: false,
                        borderColor: color1
                    },
                    {
                        label: label2,
                        data: dataArray2,
                        fill: false,
                        borderColor: color2
                    }
                ]
            };
        };

        if (selectedTab == 'router') {
            return getChartConfig('Uptime Percentage', 'Downtime Percentage', 'uptimePercentage', null, 'blue', '#742774', 1);
        } else {
            return getChartConfig('RX MB', 'TX MB', 'RxBytes', 'TxBytes', 'blue', '#742774', 1000000); // 1m for mb conversion
        }
    }

    const formatChartData2 = (data) => {
        if (selectedTab == 'router') {
            let uptimePercentages = [];
            let downtimePercentages = [];
            data.map((ele) => {
                let uptimePercentage = ele.uptimePercentage.toFixed(2);
                let downtimePercentage = 100 - ele.uptimePercentage.toFixed(2);
                uptimePercentages.push(uptimePercentage);
                downtimePercentages.push(downtimePercentage);
            });

            let chart = {
                labels: [
                    startDate.format('DD MMM YYYY'),
                    endDate.format('DD MMM YYYY')
                ],
                datasets: [
                    {
                        label: 'Uptime Percentage',
                        data: uptimePercentages,
                        fill: false,
                        borderColor: 'blue'
                    },
                    {
                        label: 'Downtime Percentage',
                        data: downtimePercentages,
                        fill: false,
                        borderColor: '#742774'
                    }
                ]
            };
            return chart;
        } else {
            let rxArray = [];
            let txArray = [];
            const mbCount = 1000000;

            data.map((ele) => {
                let rxByte = ele.RxBytes ? (ele.RxBytes / mbCount).toFixed(2) : ele.RxBytes;
                let txByte = ele.TxBytes ? (ele.TxBytes / mbCount).toFixed(2) : ele.TxBytes;
                rxArray.push(rxByte);
                txArray.push(txByte);
            });

            let chart = {
                labels: [
                    startDate.format('DD MMM YYYY'),
                    endDate.format('DD MMM YYYY')
                ],
                datasets: [
                    {
                        label: 'RX MB',
                        data: rxArray,
                        fill: false,
                        borderColor: 'blue'
                    },
                    {
                        label: 'TX MB',
                        data: txArray,
                        fill: false,
                        borderColor: '#742774'
                    }
                ]
            };

            return chart;
        }
    };

    const getRoutersList = async () => {
        setLoading(true);
        let url = 'getRouterDevicesList';
        if (adminAccess) {
            if (companyId == -1) url += `ForAdmin`;
            else url += `ForAdmin?companyId=${companyId}`;
        }

        try {
            const routerListResult = await axiosInstance.get(url);

            if (!routerListResult) {
                throw new Error('Error: routerListResult');
            }
            const routerList = routerListResult.data.data.result;
            setRoutersList(routerList);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    const formatTableData = (data) => {
        data.forEach((element, index) => {
            element.RxBytes = convertBytesToMB(element.RxBytes);
            element.TxBytes = convertBytesToMB(element.TxBytes);
            element.TotalBytes = convertBytesToMB(element.TotalBytes);
            element.Date = toggleDailyMonth ? `${element.Date.format('MMM')}-${element.Year}` : element.Date;
            element.id = index + 1;
        });

        setTableData(data);
    };

    const renderDeviceUsageReport = async (router) => {
        setLoading(true);
        let url = 'getAggregateDevicesUsage';

        console.log('selected router', selectedRouter);
        if (adminAccess) {
            if (companyId == -1) url += `ForAdmin`;
            else url += `ForAdmin?companyId=${companyId}`;
        }

        try {
            console.log('startDate', startDate.toISOString());
            console.log('companyId', companyId);

            const deviceReportResult = await axiosInstance.post(url, {
                filterValue: selectedRouter == 'All Router' ? null : selectedRouter,
                fromDate: startDate,
                toDate: endDate,
                filterMonth: toggleDailyMonth,
                companyId: companyId
            });

            if (!deviceReportResult) {
                throw new Error('Custom: Device report error!');
            }

            const deviceData = deviceReportResult.data?.data;

            deviceData.forEach((record) => {
                record.Name = selectedRouter == 'All Router' ? 'All Router' : selectedRouter;
            });

            console.log('deviceData', deviceData);
            let chartData = formatChartData(deviceData);
            formatTableData(deviceData); // call by reference
            setChartData(chartData);


        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const renderRouterUptimeReport = async (selectedRoute, startDate, endDate) => {
        setLoading(true);
        let url = 'getRouterDailyUptimePercentage';
        if (toggleDailyMonth) {
            url = 'getRouterMonthlyUptimePercentage';
        }
        if (adminAccess) {
            if (companyId == -1) url += `ForAdmin`;
            else url += `ForAdmin?companyId=${companyId}`;
        }

        try {
            const routerUptimeResult = await axiosInstance.post(url, {
                routerNameId: selectedRoute,
                fromDate: startDate,
                toDate: endDate
                // filterMonth:filterMonth,
            });

            if (!routerUptimeResult) {
                throw new Error('Custom: Router uptime error!');
            }

            const routerUptimeData = routerUptimeResult.data.data;
            let chart = formatChartData(routerUptimeData);
            formatTableData(routerUptimeData);
            setChartData(chart);
            setTableData(routerUptimeData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const convertBytesToMB = (input) => {
        const mb = 1000000;
        const isNumber = (value) => {
            return typeof value === "number" && !Number.isNaN(value);
        }

        if (isNumber(Number(input))) {
            return (Number(input) / mb).toFixed(2);
        } else {
            return input;
        }
    };

    return (
        <div style={{ backgroundColor: 'white' }}>
            {!adminAccessPromiseInProgress && adminAccess !== null && adminAccess && (
                <div className="upper-select-bar">
                    <div className="upperbar-content">
                        <Grid container spacing={3}>
                            <Grid item md={2}></Grid>
                            <Grid item md={6} className="select-bar-content">
                                <b>Company</b>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className="select-bar">
                            <Grid item md={2}></Grid>
                            <Grid item md={6} xs={12} className="select-bar-content" >
                                <FormControl className="margin">
                                    <Select labelId="demo-customized-select-label" id="demo-customized-select"
                                        value={companyId ? companyId : -1} onChange={handleSelectChangeCompany} input={<BootstrapInput />} autoWidth={true}
                                    >
                                        <MenuItem value={-1}>All</MenuItem>
                                        {companyList.map((company) => {
                                            return (
                                                <MenuItem
                                                    value={
                                                        company.companyId
                                                    }
                                                >
                                                    {company.companyName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
            <Container maxWidth="md">
                <br />
                <Grid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <Button variant="outlined" onClick={handleButtonChangeReport} value="device" size="small"
                            className={selectedTab == 'device' ? 'active-tab top-btns' : 'top-btns'} >
                            Device Usage Report
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Button variant="outlined" onClick={handleButtonChangeReport} value="router" size="small"
                            className={selectedTab == 'router' ? 'active-tab top-btns' : 'top-btns'} >
                            Router Uptime
                        </Button>
                    </Grid>
                </Grid>
                <h3 className="device-head mt-3">Device Usage Report</h3>

                <Grid container spacing={3} className="utilities-bar">
                    <Grid item md={3} sm={12} xs={12}>
                        <FormControl className="device-select">
                            <Select labelId="demo-customized-select-label" id="demo-customized-select2" value={selectedRouter}
                                onChange={(e) => setSelectedRouter(e.target.value)} input={<BootstrapInput />} autoWidth={true}
                            >
                                <MenuItem value="All Router">
                                    All Router
                                </MenuItem>
                                {routerOptionsList.map((ele) => {
                                    return (
                                        <MenuItem value={ele.name}>
                                            {ele.name}{' '}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item md={1}></Grid> */}
                    <Grid item md={6} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker disableToolbar variant="inline" format="DD/MM/YYYY" margin="normal"
                                id="date-picker-inline" label="Start Date" value={startDate} maxDate={endDate}
                                onChange={(e) => setStartDate(e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker disableToolbar variant="inline" format="DD/MM/YYYY" margin="normal"
                                id="date-picker-inline" label="End Date" value={endDate} maxDate={endDate}
                                onChange={(e) => setEndDate(e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={1} sm={12} xs={12}>
                        <Button variant="outlined" className="primary-btn" onClick={() => renderReport()} >
                            GENERATE
                        </Button>
                    </Grid>

                    <Grid item md={0.3}></Grid>

                    <Grid item md={1} sm={12} xs={12}>
                        <Button variant="outlined" className="primary-btn" onClick={() => download(tableData, 'MIN_Report')} >
                            DOWNLOAD
                            <ArrowDownward />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item md={3} sm={12} xs={12}>
                        <span className="toggle-label">View</span>
                        <Button variant="outlined" className={viewMode == 'table' ? 'toggle-btn bg-active' : 'toggle-btn'}
                            onClick={() => { setViewMode('table'); }} >
                            Table
                        </Button>
                        <Button variant="outlined" className={viewMode == 'graph' ? 'toggle-btn bg-active' : 'toggle-btn'}
                            onClick={() => { setViewMode('graph'); }} >
                            Graph
                        </Button>
                    </Grid>

                    <Grid item md={4} sm={12} xs={12}>
                        <span className="toggle-label">Frequency</span>
                        <Button variant="outlined" onClick={() => { setFilterMonth(false); }}
                            className={toggleDailyMonth ? 'toggle-btn' : 'toggle-btn bg-active'} >
                            Daily
                        </Button>
                        <Button variant="outlined" onClick={() => { setFilterMonth(true); }}
                            className={!toggleDailyMonth ? 'toggle-btn' : 'toggle-btn bg-active'} >
                            Monthly
                        </Button>
                    </Grid>
                </Grid>

                {loading ? (
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {viewMode == 'table' ? (
                            <Table data={tableData} tableName={selectedTab.current} />
                        ) : (
                            <Chart chartData={chartData} />
                        )}
                    </>
                )}
            </Container>
        </div>
    );
};

export default Reports;
