import React from 'react';
import { SvgIcon } from '@mui/material';

const SearchIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 37 37" {...props}>
            <path
                d="M35.8087 34.8813L25.6113 24.6699C28.2123 22.1582 29.8237 18.6956 29.8237 14.8768C29.8237 7.22475 23.359 1 15.4119 1C7.46475 1 1 7.22475 1 14.8768C1 22.5289 7.46475 28.7537 15.4119 28.7537C18.9002 28.7537 22.1017 27.553 24.597 25.5607L34.8191 35.7971C34.9536 35.9326 35.1334 36 35.3139 36C35.4855 36 35.6564 35.9385 35.7895 35.8156C36.0627 35.5625 36.0709 35.1443 35.8087 34.8813ZM15.4119 27.4321C8.22171 27.4321 2.37256 21.8 2.37256 14.8768C2.37256 7.95361 8.22171 2.3216 15.4119 2.3216C22.602 2.3216 28.4512 7.95361 28.4512 14.8768C28.4512 21.8 22.6013 27.4321 15.4119 27.4321Z"
                fill="#128958"
                stroke="#128958"
                strokeWidth="0.6"
            />
        </SvgIcon>
    );
};

export default SearchIcon;
