import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const download = (csvData, fileName) => {
    console.log(csvData, 'csvvdattaa');

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileNameUniq = Date.now();

    const exportToCSV = (csvData1, fileName1) => {
        const ws = XLSX.utils.json_to_sheet(csvData1);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName1 + fileExtension);
    };

    let file = exportToCSV(csvData, fileName + '_' + fileNameUniq);
    return file;
};

export default download;
