export default {
    root: {
        '& tr:first-child': {
            '& th': {
                paddingTop: 0
            }
        },

        '& th': {
            borderBottomColor: '#424242'
        }
    }
};
