import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CookieManager } from '../../helpers';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    iframe: {}
}));

const Users = (props) => {
    const classes = useStyles();
    const [token, setToken] = useState();
    const [redirectToSignIn, setRedirectToSignIn] = useState(false);

    useEffect(() => {
        let token = CookieManager.getDataFromLocalStorage('token');
        if (token) setToken(token);
        else {
            setRedirectToSignIn(true);
        }
    }, [token]);

    window.addEventListener(
        'message',
        function (e) {
            if (token) {
                if (e.data) {
                    if (e.data.status === 200) {
                        CookieManager.setDataToLocalStorage(
                            'token',
                            e.data.token
                        );
                        setToken(e.data.token);
                    } else if (e.data.status === 401) {
                        setRedirectToSignIn(true);
                    }
                }
            }
        },
        false
    );

    if (redirectToSignIn) {
        return <Redirect to="/sign-in" />;
    }

    return (
        <div className={classes.root}>
            {token && (
                <iframe
                    id="MyFrame"
                    title="home"
                    src={`${process.env.REACT_APP_RBAC_FRONTEND}users?token=${token}`}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                />
            )}
        </div>
    );
};

export default Users;
