import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CookieManager } from '../../helpers';
import { Redirect } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    const [redirectToHomePage, setRedirectToHomePage] = useState(false);

    window.addEventListener(
        'message',
        function (e) {
            if (e.data) {
                if (e.data.status === 200) {
                    console.log(e);
                    CookieManager.setDataToLocalStorage(
                        'token',
                        e.data.data.token
                    );
                    CookieManager.setDataToLocalStorage(
                        'userInfo',
                        e.data.data.userInfo
                    );
                    setRedirectToHomePage(true);
                }
            }
        },
        false
    );

    if (redirectToHomePage) {
        return <Redirect to="/" />;
    }

    return (
        <div className={classes.root}>
            <iframe
                title="login"
                src={`${process.env.REACT_APP_RBAC_FRONTEND}sign-in`}
                frameBorder="0"
                width="100%"
                height="100%"
            />
        </div>
    );
};

export default Dashboard;
