import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem, Avatar } from '@mui/material'
import LogoutIcon from '@mui/icons-material/ExitToApp';
import logo from '../../../assets/images/maxis-logo.png';
import { rbacAxiosInstance, CookieManager } from '../../../helpers';
import './styles.css';

const Header = () => {
    let selectedTab = useState('/dashboard');
    const location = useLocation();
    const currentPath = location.pathname;
    selectedTab = currentPath;

    let userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
    }
    console.log(userInfo);

    const logoutClick = async () => {
        console.log('logout called');
        await rbacAxiosInstance
            .post('/logout')
            .then((data) => {
                if (data.data === 200) {
                    CookieManager.clearStorage();
                }
            })
            .catch((data) => {
                CookieManager.clearStorage();
            });
    };


    return (
        <div style={{ background: 'white' }}>
            <nav className="navbar navbar-expand-lg navbar-light">
                <button
                    className="navbar-toggler navbar-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="/">
                    <img src={logo} width="70" height="45" />
                </a>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <Link
                                className={
                                    selectedTab == '/dashboard'
                                        ? 'nav-link highlet'
                                        : 'nav-link'
                                }
                                to="/dashboard"
                            >
                                Dashboard <span className="sr-only">(current)</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={
                                    selectedTab == '/reports'
                                        ? 'nav-link highlet'
                                        : 'nav-link'
                                }
                                to="/reports"
                            >
                                Report
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/myprofile"
                                className={
                                    selectedTab == '/myprofile'
                                        ? 'nav-link highlet'
                                        : 'nav-link'
                                }
                            >
                                Profile
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to='/users'
                                className={
                                    selectedTab == '/users'
                                        ? 'nav-link highlet'
                                        : 'nav-link'
                                }
                            >
                                User Management
                            </Link>
                        </li>

                        <li className="nav-item">
                            <form className="form-inline my-2 my-lg-0">
                                <Avatar
                                    src="/broken-image.jpg"
                                    className="avatar margin-top"
                                />
                                <span className="user-info">
                                    <b className="role-text">{`${userInfo?.firstName} ${userInfo?.lastName}`}</b>
                                    <small>{userInfo?.username}</small>
                                </span>
                                <div className="vertical"></div>
                                <Link to="/sign-in">
                                    <LogoutIcon
                                        className="logout-icon"
                                        onClick={logoutClick}
                                    />
                                </Link>
                            </form>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default Header;
