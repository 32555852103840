import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

import Header from '../../views/newDashboard/header/header';

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            // paddingTop: 64
        }
    },
    shiftContent: {
        paddingLeft: 0
    },
    content: {
        height: '100%'
    }
}));

const Main = (props) => {
    const { children } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
            })}
        >
            <Header />
            <main className={classes.content}>{children}</main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
