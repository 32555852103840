import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiFormControl from './MuiFormControl';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiTableBody from './MuiTableBody';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableFooter from './MuiTableFooter';
import MuiTypography from './MuiTypography';

export default {
    MuiButton,
    MuiIconButton,
    MuiFormControl,
    MuiOutlinedInput,
    MuiPaper,
    MuiTableBody,
    MuiTableCell,
    MuiTableHead,
    MuiTableFooter,
    MuiTypography
};
