import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@mui/styles';

import {
	Card,
	CardHeader,
	CardContent,
	Button,
	Typography,
	MobileStepper,
	Grid,
	Container,
	MenuItem,
	FormControl,
	Select,
	InputBase,
	InputAdornment,
	CircularProgress,
	Pagination
} from '@mui/material';

import { usePromiseTracker, trackPromise } from 'react-promise-tracker';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import SearchIcon from '@mui/icons-material/Search';
import RouterIcon from '@mui/icons-material/Router';

import Model from './routerModel';
import { axiosInstance, rbacAxiosInstance } from '../../../helpers/axiosInstance';
import bgImg from '../../../assets/images/backgrounds/bg_admin.jpg';
import routerIcon from '../../../assets/images/0607_Maxis Source/0607_Maxis Source/01_Icon/Router Offline.svg';
import routerOnlineIcon from '../../../assets/images/0607_Maxis Source/0607_Maxis Source/01_Icon/Router Online.svg';
import uptimeIcon from '../../../assets/images/0607_Maxis Source/0607_Maxis Source/01_Icon/Router Uptime.svg';
import cardBg from '../../../assets/images/icons/wave.png';
import './styles.scss';

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 275,
		background: 'black'
	},
	mobileRoot: {
		flexGrow: 1,
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '25%',
		background: 'white'
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	pos: {
		marginBottom: 12
	},
	img: {
		height: 255,
		display: 'block',
		overflow: 'hidden',
		width: '100%'
	},
	search: {
		position: 'relative',
		border: '1px solid lightgray',
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			// marginLeft: theme.spacing(3),
			width: 'auto'
		}
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'lightgray'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch'
		}
	}
}));

const BootstrapInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3)
		}
	},
	sectionCenterContent: {
		padding: theme.spacing(5, 0),
		textAlign: 'center',
		position: "absolute",
		left: "45%"
	},
	cardContent: {
		height: '20em',
	},
	pagination: {
		marginTop: theme.spacing(4),
	},
	paginationContainer: {
		width: '100%',
		display: 'grid',
		placeItems: 'end',
		[theme.breakpoints.down('xs')]: {
			placeItems: 'center',
		},

	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
		}
	}
}))(InputBase);

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const _cardsData = [
	{
		label: 'Router Online',
		count: 788,
		bgColor: '#285C4D',
		bgImg:
			'https://media.istockphoto.com/photos/abstract-blue-and-black-are-light-pattern-with-the-gradient-is-the-picture-id1201989745?k=20&m=1201989745&s=612x612&w=0&h=527cNWAUjTUDrjXc4nL6XF4GStvI-6A_8SAnK4Tg6mQ='
	},
	{
		label: 'Router Offline',
		count: 5,
		bgColor: '#210d36',
		bgImg: { cardBg }
		// 'https://im.ge/i/uNjUkD'
		// 'https://media.istockphoto.com/photos/wooden-floor-close-up-picture-id1056043320?k=20&m=1056043320&s=612x612&w=0&h=WIFi0pQbIpTjbxCJp9bx6MBW3DNA0iyK5o4Bt9IbadI='
	},
	{
		label: 'Router Uptime',
		count: '99.97%',
		bgColor: 'darkslategray',
		bgImg:
			'https://garden.spoonflower.com/c/12530951/p/f/m/Aha5wMJdXf-U3wI9G8oPqCzLD4xhiiA1dUzYIT12sKQAnDFoj800/Ash%20Gray%20Solid%20Color.jpg'
	}
];

const Dashboard = () => {
	const classes = useStyles();
	const theme = useTheme();

	const [width, setWidth] = useState(window.innerWidth);
	const [activeStep, setActiveStep] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	const [routerCards, setRouterCards] = useState([]);
	const [deviceSelectedState, setDeviceSelectedState] = useState('all');
	const [cardsData, setCardsData] = useState(_cardsData);
	const [search, setSearch] = useState('');
	const [startRecord, setStartRecord] = useState(1);
	// const [pageSize, setPageSize] = useState(20);
	const pageSize = 20;
	const [totalRecord, setTotalRecord] = useState(0);
	const [searchText, setSearchText] = useState("");
	const [adminAccess, setAdminAccess] = useState(false);
	const [page, setPage] = useState(1);
	const [companyList, setCompanyList] = useState([]);
	const [companyId, setCompanyId] = useState(-1);
	const [filter, setFilter] = useState(10);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [hostIP, setHostIP] = useState(null);

	const { promiseInProgress: overallPromiseInProgress } = usePromiseTracker({ area: 'overall' });
	const { promiseInProgress: devicesPromiseInProgress } = usePromiseTracker({ area: 'devices' });
	const { promiseInProgress: adminAccessPromiseInProgress } = usePromiseTracker({ area: 'adminAccess' });

	useEffect(() => {
		// sucessToaster("Please enter Host IP!");

		trackPromise(
			rbacAxiosInstance.post('userHasAccessToModule', { system: 'MIN', module: 'ADMIN' })
				.then(() => {
					setAdminAccess(true);
					console.log('got admin access');
					axiosInstance.get('getCompanyList')
						.then(response => {
							console.log(response.data.data);
							setCompanyList(response.data.data);
						})
						.catch(err => {
							console.log(err);
						})
				})
				.catch(err => {
					console.log('dont have admin access', err.response.status);
					if (err?.response) {
						if (err.response.status === 448) {
							setAdminAccess(false);
						}
					}

					console.log(err);
				}), 'adminAccess'
		)

	}, []);

	useEffect(() => {
		if (!adminAccess) {
			axiosInstance.get('getCompanyDetails')
				.then(response => {
					setSelectedCompany(response.data.data);
				})
				.catch(err => {
					console.log(err);
				})
		}
	}, [adminAccess])

	useEffect(() => {
		let endpoint = '';
		if (adminAccess) {
			endpoint = 'getOverallRouterInfoForAdmin'
			if (companyId !== -1) {
				endpoint = endpoint + `?companyId=${companyId}`
			}
		}
		else {
			endpoint = 'getOverallRouterInfo'
		}
		trackPromise(
			axiosInstance
				.get(endpoint)
				.then(response => {
					let data = [...cardsData];
					data[0].count = response?.data?.data[0]?.online;
					data[1].count = response?.data?.data[0]?.offline;
					console.log('uptime', Number(response?.data?.data[0]?.uptimePercentage).toFixed(2));
					data[2].count = Number(response?.data?.data[0]?.uptimePercentage).toFixed(2) + ' %';
					setCardsData(data);
				})
				.catch(error => {
					console.log(error);
				}),

			'overall'
		);
	}, [adminAccess, companyId, companyList])

	useEffect(() => {
		getRouters();
	}, [deviceSelectedState, search, startRecord, adminAccess, companyId, companyList])
	// useEffect(() => {

	// }, [search,startRecord]);


	const searchClick = () => {
		setSearch(searchText);
	}

	const getRouters = () => {
		let endpoint = ''
		if (adminAccess) {
			endpoint = `getRouterDevicesListForAdmin?forDashboard=true&pageSize=${pageSize}&startRecord=${startRecord}`
			if (companyId !== -1 && companyId !== null && companyId !== undefined) {
				endpoint = endpoint + `&companyId=${companyId}`
			}
		}
		else {
			endpoint = `getRouterDevicesList?forDashboard=true&pageSize=${pageSize}&startRecord=${startRecord}`
		}
		if (search) {
			endpoint = endpoint + `&filterField=name&filterValue=${searchText}`
		}
		if (deviceSelectedState === 'online') {
			endpoint = endpoint + `&filterStatus=Up`
		}
		else if (deviceSelectedState === 'offline') {
			endpoint = endpoint + `&filterStatus=Down`
		}

		trackPromise(
			axiosInstance
				.get(endpoint)
				.then(response => {
					// cards = response.data.data.result;
					setRouterCards(response.data.data.result);
					setTotalRecord(response.data.data.totalRecord);
				})
				.catch(error => {
					console.log(error);
				}),
			'devices'
		);
	};


	const handlePaginationPageChange = (page) => {
		console.log((page * pageSize) + 1, "pageee")
		let startRec = (page * pageSize) + 1;
		setStartRecord(startRec);
		setPage(page + 1);
	}

	const handleOpen = (host) => {
		setOpen(true);
		setHostIP(host);
	};

	const handleClose = () => setOpen(false);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	const handleStepChange = step => {
		setActiveStep(step);
	};

	const handleChangeCompany = event => {
		setCompanyId(event.target.value);
		let companyIndex = companyList.findIndex(data => data.companyId === event.target.value);
		setSelectedCompany(companyIndex >= 0 ? companyList[companyIndex] : null);
	};

	const handleChangeFilter = event => {
		setFilter(event.target.value);
	}

	const deviceChange = e => {
		let value = e.currentTarget.value;
		setDeviceSelectedState(value);
	};

	return (

		<div style={{ 'background-color': 'white' }}>
			{!adminAccessPromiseInProgress && adminAccess !== null && adminAccess && 
				<div className="upper-select-bar">
					<div className="upperbar-content">
						<Grid container spacing={3}>
							<Grid item md={2}></Grid>
							<Grid item md={6} className="select-bar-content">
								<b>Company</b>
							</Grid>
						</Grid>

						<Grid container spacing={3} className="select-bar">
							<Grid item md={2}></Grid>
							<Grid item md={6} xs={12} className="select-bar-content">
								<FormControl className="margin">
									<Select
										labelId="demo-customized-select-label"
										id="demo-customized-select"
										value={companyId ? companyId : -1}
										onChange={handleChangeCompany}
										input={<BootstrapInput />}
										autoWidth={true}>
										<MenuItem value={-1}>All</MenuItem>
										{companyList.map(company => {
											return <MenuItem value={company.companyId}>{company.companyName}</MenuItem>
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</div>
				</div>
			}

			<div
				className="bg-img-container"
				style={selectedCompany?.backgroundColor ? {
					'background-image': `linear-gradient(to right, ${selectedCompany.backgroundColor} , black)`,
					'background-repeat': 'no-repeat',
					'background-size': '100% 70%'
				} : {
					'background-image': `url(${bgImg})`,
					'background-repeat': 'no-repeat',
					'background-size': '100% 70%'
				}}>
				<br />
				<br />
				<Container maxWidth="md">
					{(selectedCompany && selectedCompany.backgroundImage) ?
						<img className="overview" style={{ height: '40px' }} src={`data:image/png;base64,${btoa(String.fromCharCode.apply(null, selectedCompany?.backgroundImage?.data))}`} />
						:
						<Typography className="overview">Overview</Typography>
					}

					{overallPromiseInProgress ?
						<div className={classes.sectionCenterContent}>
							<CircularProgress />
						</div>
						:
						<div>
							{width <= 768 ? (
								<div>
									<AutoPlaySwipeableViews
										axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
										index={activeStep}
										onChangeIndex={handleStepChange}
										enableMouseEvents>
										{cardsData.map((step, index) => (
											<div key={step.label}>
												{Math.abs(activeStep - index) <= 2 ? (
													<Card
														className={classes.root}
														style={{
															background: step.bgColor,
															// backgroundImage: `url(${card.bgImg ? card.bgImg : card.bgImg})`
															backgroundImage: `url(${cardBg})`
														}}>
														<CardContent>
															<Typography className="router-card-text" gutterBottom>
																{/* <RouterIcon /> */}
																<img src={routerIcon} className="top-card-icon" />
																{step.label}
															</Typography>
															<Typography
																className="router-card-count"
																gutterBottom>
																{step.count}
															</Typography>
														</CardContent>
													</Card>
												) : null}
											</div>
										))}
									</AutoPlaySwipeableViews>

									<div className="mobile-dots">
										<MobileStepper
											variant="dots"
											steps={cardsData.length}
											position="static"
											activeStep={activeStep}
											className={classes.mobileRoot}
										/>
									</div>
								</div>
							) : (
								<Grid
									container
									spacing={3}>
									{cardsData.map((card) => {
										return (
											<Grid item md={4}>
												<Card
													className={classes.root}
													style={{
														background: card.bgColor,
														// backgroundImage: `url(${card.bgImg ? card.bgImg : card.bgImg})`
														backgroundImage: `url(${cardBg})`
													}}>
													<CardContent>
														<Typography className="router-card-text" gutterBottom>
															{card.label === 'Router Runtime' ? (
																<img src={uptimeIcon} className="top-card-icon" />
															) : card.label === 'Router Offline' ? (
																<img src={routerIcon} className="top-card-icon" />
															) : (
																<img
																	src={routerOnlineIcon}
																	className="top-card-icon"
																/>
															)}
															{card.label}
														</Typography>
														<Typography
															className={
																card.label === 'Router Offline'
																	? 'router-card-count-danger'
																	: 'router-card-count'
															}
															gutterBottom>
															{card.count}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
										);
									})}
								</Grid>
							)}
						</div>}
				</Container>
			</div>
			<br />
			<br />
			<div>
				<Container maxWidth="md">
					<Typography className="device-text" color="textSecondary">
						Devices
					</Typography>
					<br />

					<Grid container spacing={3}>
						<Grid item md={4} sm={12} xs={12}>
							<Button
								className="device-btns"
								variant="outlined"
								value="all"
								onClick={deviceChange}
								style={{
									background: deviceSelectedState == 'all' ? '#b6fcbd' : ''
								}}>
								All
							</Button>
							<Button
								className="device-btns"
								variant="outlined"
								value="online"
								onClick={deviceChange}
								style={{
									background: deviceSelectedState == 'online' ? '#b6fcbd' : ''
								}}>
								Online
							</Button>
							<Button
								className="device-btns"
								variant="outlined"
								value="offline"
								onClick={deviceChange}
								style={{
									background: deviceSelectedState == 'offline' ? '#b6fcbd' : ''
								}}>
								Offline
							</Button>
						</Grid>
						<Grid item md={1}></Grid>
						<Grid
							item
							md={2}
							sm={12}
							xs={12}
							style={{ 'padding-right': '0px', 'padding-left': '0px' }}>
							<FormControl className="device-select">
								<Select
									labelId="demo-customized-select-label"
									id="demo-customized-select"
									value={filter}
									onChange={handleChangeFilter}
									input={<BootstrapInput />}
									autoWidth={true}>
									<MenuItem value={10}>Router name</MenuItem>
									<MenuItem value={20}>IP address</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							md={4}
							sm={12}
							xs={12}
							style={{ 'padding-left': '0px', 'padding-right': '0px' }}>
							<div className={classes.search}>
								<InputBase
									placeholder="Search…"
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput
									}}
									inputProps={{ 'aria-label': 'search' }}
									startAdornment={
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									}
									onChange={(e) => { setSearchText(e.target.value) }}
								/>
							</div>
						</Grid>
						<Grid item md={1} sm={12} xs={12}>
							<Button className="search-btn" variant="outlined" onClick={searchClick}>
								Search
							</Button>
						</Grid>
					</Grid>
				</Container>
			</div>
			<br />
			<div>
				<Container maxWidth="md">
					<Typography className="cimb-text" color="textSecondary">

					</Typography>
					<br />

					<div className={classes.paginationContainer}>
						<Pagination
							count={parseInt(totalRecord / pageSize) + (totalRecord % pageSize > 0 ? 1 : 0)}
							variant="outlined"
							shape="rounded"
							className={classes.pagination}
							color='primary'
							page={page}
							onChange={(event, page) => { handlePaginationPageChange(page - 1) }} />
					</div>

					<br />
					<Grid container spacing={3}>
						{overallPromiseInProgress || devicesPromiseInProgress ?
							<div className='card-progress-container'>
								<CircularProgress />
							</div>
							:
							<>
								{routerCards.map(card => {
									return (
										<Grid item md={3} sm={12} xs={12}>
											<Card
												onClick={() => handleOpen(card.host)}
												className={
													card.status == 'Up'
														? 'bg-gradent-green'
														: 'bg-gradent-danger'
												}>
												<CardHeader
													avatar={
														<span
															aria-label="recipe"
															className={
																card.status == 'Up'
																	? 'router-card-icon'
																	: 'router-card-icon-offline'
															}>
															<RouterIcon />
														</span>
													}

													title={card.customersDeviceName}
												/>
												<hr className="hr" />
												<CardContent>
													{card.msisdn &&
														<Typography className="card-rows">
															<span className="card-sub-head">MSISDN</span>{' '}
															<span className="card-head-val">{card.msisdn}</span>
														</Typography>
													}
													{card.simNo &&
														<Typography className="card-rows">
															<span className="card-sub-head">SIM no</span>{' '}
															<span className="card-head-val">{card.simNo}</span>
														</Typography>
													}
													{card.host &&
														<Typography className="card-rows">
															<span className="card-sub-head">SIM IP</span>{' '}
															<span className="card-head-val">{card.host}</span>  <br />
															<span className="card-head-val" style={{ "white-space": "nowrap" }}>{card.location}</span>
														</Typography>
													}
													{card.ratePlan &&
														<Typography className="card-rows">
															<span className="card-sub-head">Rate plan</span>
															<span className="card-head-val">{card.ratePlan}</span>
														</Typography>
													}
													{card.currentData &&
														<Typography className="card-rows">
															<span className="card-sub-head">Current data</span>{' '}
															<span className="card-head-val">{card.currentData ? card.currentData : '-'}</span>
														</Typography>
													}
													{card.expiryDate &&
														<Typography className="card-rows">
															<span className="card-sub-head">Expiry Date</span>{' '}
															<span className="card-head-val">{card.expiryDate ? card.expiryDate : "-"}</span>
														</Typography>
													}
												</CardContent>
											</Card>
										</Grid>
									);
								})}
							</>
						}
					</Grid>
					<br />



				</Container>
			</div>

			{open && hostIP && <Model open={open} hostIP={hostIP} handleClose={handleClose} />}
		</div>
	);
};

export default Dashboard;
