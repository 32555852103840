// import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

export const errorToaster = (msg) => {
    toast.error(msg);
};

export const sucessToaster = (msg) => {
    toast.success(msg);
};
