import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { 
    Modal,
    Backdrop,
    Fade,
    TextField,
    Button,
    Grid
} from '@mui/material';
import { axiosInstance } from '../../../helpers/axiosInstance';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '1px solid #000',
        // boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const RouterModel = ({ hostIP, open, handleClose }) => {
    const classes = useStyles();

    const [routerDetail, setRouterDetail] = useState();

    useEffect(() => {
        axiosInstance
            .get(`getRouter/${hostIP}`)
            .then((response) => {
                setRouterDetail(response.data.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={Backdrop}
                slotProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {routerDetail && (
                            <div className="model-container">
                                <b className="model-head">
                                    Router Details
                                </b>

                                <div className="model-data-grid">
                                    <Grid container spacing={3}>
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                Router Name
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.customersDeviceName
                                                    ? routerDetail.customersDeviceName
                                                    : '-'}
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                MSISDN
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.msisdn
                                                    ? routerDetail.msisdn
                                                    : '-'}
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                SIM No
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.simNo
                                                    ? routerDetail.simNo
                                                    : '-'}
                                            </span>
                                        </Grid>
                                        {/* </Grid> */}

                                        {/* <Grid container spacing={3}> */}
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                SIM IP
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.host
                                                    ? routerDetail.host
                                                    : '-'}
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                Rate Plan
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.ratePlan
                                                    ? routerDetail.ratePlan
                                                    : '-'}
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            className="model-data-block"
                                        >
                                            <p className="model-data-key">
                                                Current data utilization
                                            </p>
                                            <span className="model-data-values">
                                                {routerDetail?.currentDataUtilization
                                                    ? routerDetail.currentDataUtilization
                                                    : '-'}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="model-input-container">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <p className="input-para-text">
                                                Router IP Address
                                            </p>
                                            <TextField
                                                className="input-data-entry"
                                                onChange={(e) => {console.log(e)}}
                                                value={hostIP}
                                                id="outlined-basic"
                                                label="Router IP"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="input-para-text">
                                                Configurable field Details
                                            </p>
                                            <TextField
                                                className="input-data-entry"
                                                onChange={(e) => {console.log(e)}}
                                                id="outlined-basic"
                                                label="Name"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <br />
                                <br />
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            className="model-close-btn"
                                            onClick={handleClose}
                                        >
                                            close
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/*<Button className="model-submit-btn" onClick={submitClick}>submit</Button>*/}
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default RouterModel;
