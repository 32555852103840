import React from 'react';
import { SvgIcon } from '@mui/material';

const CalendarIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M19.8632 20.64H3.7477C3.27338 20.6434 2.81589 20.4644 2.46981 20.14C2.12372 19.8157 1.9155 19.3707 1.88818 18.8972V6.69893C1.90559 6.22189 2.11079 5.77105 2.45908 5.4446C2.80737 5.11816 3.27054 4.94256 3.7477 4.95605H19.8632C20.3404 4.94256 20.8036 5.11816 21.1519 5.4446C21.5001 5.77105 21.7053 6.22189 21.7227 6.69893V18.8972C21.6954 19.3707 21.4872 19.8157 21.1411 20.14C20.795 20.4644 20.3375 20.6434 19.8632 20.64Z"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M1.88818 9.60339H21.7223"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M6.22681 3.50403V6.69891"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M17.3833 3.50403V6.69891"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M1.88818 14.8311H21.7223"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M8.70605 9.60339V20.64"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M15.5244 9.60339V20.64"
                stroke="#128958"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </SvgIcon>
    );
};

export default CalendarIcon;
