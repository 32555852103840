import React from 'react';
import { SvgIcon } from '@mui/material';

const GraphIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M1.6001 3.19995V20.2399H21.8401"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M8.16016 10.24H4.16016V20.08H8.16016V10.24Z"
                fill="#128958"
            />
            <path
                d="M13.7603 6.15991H9.76025V20.1599H13.7603V6.15991Z"
                fill="#128958"
            />
            <path
                d="M19.3599 9.59998H15.3599V20.16H19.3599V9.59998Z"
                fill="#128958"
            />
        </SvgIcon>
    );
};

export default GraphIcon;
