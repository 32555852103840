export default {
    root: {
        fontWeight: 400
    },

    contained: {
        boxShadow:
            '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        backgroundColor: '#FFFFFF'
    },

    /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
    containedSizeLarge: {
        padding: '8px 15px',
        fontSize: 14
    }
};
