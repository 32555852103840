import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout, Main as MainLayout } from './layouts';
import NewDashboard from './views/newDashboard/dashboard/dashboard';
import Reports from './views/newDashboard/reports/reports';
import Profile from './views/newDashboard/profile/profile';
import UpdatePassword from './views/newDashboard/profile/updatePassword';
import {
    NotFound as NotFoundView,
    SignIn as SignInView,
    UserGroup as UserGroupView,
    Users as UserView,
    Support,
    Profile as ProfileView,
    RouterUptime as RouterUptimeView,
    RouterDetails as RouterDetailsView,
    DeviceUsageReport as DeviceUsageReportView,
    EditRouter as EditRouterView
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <RouteWithLayout component={ProfileView} exact layout={MainLayout} path="/profile" />
            <RouteWithLayout component={UserGroupView} exact layout={MainLayout} path="/userGroup" />
            <RouteWithLayout component={UserView} exact layout={MainLayout} path="/users" />
            <RouteWithLayout component={RouterUptimeView} exact layout={MainLayout} path="/routerUptime" />
            <RouteWithLayout
                component={RouterDetailsView}
                exact
                layout={MainLayout}
                path="/routerDetails"
            />
            <RouteWithLayout
                component={EditRouterView}
                exact
                layout={MainLayout}
                path="/editRouter/:hostIP"
            />
            <RouteWithLayout
                component={ProfileView}
                exact
                layout={MainLayout}
                path="/profile"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
            />
            <RouteWithLayout
                component={Support}
                exact
                layout={MainLayout}
                path="/support"
            />
            <RouteWithLayout
                component={NewDashboard}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <RouteWithLayout
                component={Reports}
                exact
                layout={MainLayout}
                path="/reports"
            />
            <RouteWithLayout
                component={Profile}
                exact
                layout={MainLayout}
                path="/myprofile"
            />
            <RouteWithLayout
                component={UpdatePassword}
                exact
                layout={MainLayout}
                path="/updatePassword"
            />

            <RouteWithLayout
                component={DeviceUsageReportView}
                exact
                layout={MainLayout}
                path="/device-usage-report"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
