import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import TouchMoveIcon from '../images/icon-touch-move.png';

const dateFnsUtils = new DateFnsUtils();

const graphHeightCustomBreakpoint = '@media (min-height: 920px)';

const sectionSidePadding = 30;
const sectionSidePaddingSmUp = 38;
const txBytesColor = '#dbe342';
const rxBytesColor = '#95e82c';
const totalBytesColor = '#128958';

const useStyles = makeStyles((theme) => ({
    graph: {
        marginTop: theme.spacing(5)
    },

    graphScrollable: {
        marginBottom: theme.spacing(3),
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            padding: theme.spacing(3),
            fontSize: 20
        },

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },

    graphScrollableIcon: {
        marginRight: theme.spacing(2)
    },

    graphScrollableIconImage: {
        verticalAlign: 'middle'
    },

    graphHeader: {
        fontSize: 14,
        textAlign: 'center',

        [theme.breakpoints.up('sm')]: {
            fontSize: 16
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 20
        }
    },

    graphHeading: {
        fontWeight: 'bold'
    },

    graphLegend: {
        overflow: 'hidden'
    },

    graphLegendInner: {
        marginTop: theme.spacing(3),
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(5),
            marginLeft: -theme.spacing(3),
            marginRight: -theme.spacing(3)
        },

        [theme.breakpoints.up('md')]: {
            marginLeft: -theme.spacing(5),
            marginRight: -theme.spacing(5)
        }
    },

    graphLegendItem: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        },

        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5)
        }
    },

    graphLegendLine: {
        width: 30,
        height: 2,
        marginRight: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            width: 50
        }
    },

    graphArea: {
        margin: `${theme.spacing(3)}px -${sectionSidePadding}px 0`,
        overflow: 'auto',

        [theme.breakpoints.up('sm')]: {
            margin: `${theme.spacing(5)}px -${sectionSidePaddingSmUp}px 0`
        }
    },

    graphAreaInner: {
        height: '60vh',
        position: 'relative',
        overflow: 'hidden',

        [`${graphHeightCustomBreakpoint}`]: {
            height: 550
        }
    },

    graphAreaCanvas: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: sectionSidePadding,
        right: sectionSidePadding,

        [theme.breakpoints.up('sm')]: {
            left: sectionSidePaddingSmUp,
            right: sectionSidePaddingSmUp
        }
    },

    pdfReport: {
        width: '21cm',
        height: '29.7cm',
        backgroundColor: 'white'
    },

    header: {
        backgroundColor: theme.palette.primary.main,
        padding: '20px',
        color: 'white',
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: '100px'
    },

    headerText: {
        paddingLeft: '20px',
        fontWeight: '400'
    }
}));

const Graph = (props) => {
    const { graphIsScrollable, graphAreaRef } = props;
    const classes = useStyles();

    return (
        <div className={classes.graph} id="graph">
            {graphIsScrollable && (
                <div className={classes.graphScrollable}>
                    <div className={classes.graphScrollableIcon}>
                        <img
                            src={TouchMoveIcon}
                            alt=""
                            className={classes.graphScrollableIconImage}
                        />
                    </div>

                    <div>
                        Please scroll left/right to
                        <br />
                        browse the graph.
                    </div>
                </div>
            )}

            <div className={classes.graphHeader}>
                <div className={classes.graphHeading}>
                    Device Usage Graph (
                    {dateFnsUtils.format(
                        new Date(props.fromDate),
                        'dd/MM/yyyy'
                    )}{' '}
                    -{' '}
                    {dateFnsUtils.format(new Date(props.toDate), 'dd/MM/yyyy')})
                </div>

                <div className={classes.graphLegend}>
                    <div className={classes.graphLegendInner}>
                        <div className={classes.graphLegendItem}>
                            <span
                                className={classes.graphLegendLine}
                                style={{ backgroundColor: txBytesColor }}
                            />
                            TX Bytes
                        </div>

                        <div className={classes.graphLegendItem}>
                            <span
                                className={classes.graphLegendLine}
                                style={{ backgroundColor: rxBytesColor }}
                            />
                            RX Bytes
                        </div>

                        <div className={classes.graphLegendItem}>
                            <span
                                className={classes.graphLegendLine}
                                style={{ backgroundColor: totalBytesColor }}
                            />
                            Total Bytes
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.graphArea} ref={graphAreaRef}>
                <div
                    className={classes.graphAreaInner}
                    style={{ minWidth: props.deviceUsageReportDataLength * 60 }}
                >
                    <div className={classes.graphAreaCanvas}>
                        <Line data={props.chartData} options={props.options} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Graph;
