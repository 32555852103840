import { CookieManager } from '../helpers';
const config = require('../common/config');
const axios = require('axios');

let isRefreshing = false;
let failedQueue = [];

const rbacAPI = config.rbacAPI;
const API = config.appAPI;

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const rbacAxiosInstance = axios.create({
    baseURL: rbacAPI,
    headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json'
    }
});

const axiosInstance = axios.create({
    baseURL: API,
    headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json'
    }
});

rbacAxiosInstance.interceptors.request.use((request) => {
    request.headers.Authorization = CookieManager.getDataFromLocalStorage('token');
    return request;
});

rbacAxiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            const originalRequest = error.config;
            if (
                error.response.status === 401 &&
                originalRequest.url === rbacAPI + 'refreshToken'
            ) {
                CookieManager.clearStorage();
                window.location.href = '/sign-in';
                return Promise.reject(error);
            }
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            originalRequest.headers['Authorization'] =
                                'Bearer ' + token;
                            return rbacAxiosInstance(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }

                originalRequest._retry = true;
                isRefreshing = true;
                return new Promise(function (resolve, reject) {
                    axios
                        .get(rbacAPI + 'refreshToken', {
                            headers: {
                                Authorization:
                                    CookieManager.getDataFromLocalStorage(
                                        'token'
                                    )
                            }
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                CookieManager.setDataToLocalStorage(
                                    'token',
                                    res.data.data.token
                                );
                                CookieManager.setDataToLocalStorage(
                                    'userInfo',
                                    res.data.data.userInfo
                                );
                                processQueue(null, res.data.data.token);
                                resolve(rbacAxiosInstance(originalRequest));
                            }
                        })
                        .then((err) => {
                            processQueue(err, null);
                            reject(err);
                        })
                        .catch((err) => {
                            CookieManager.clearStorage();
                            window.location.href = '/sign-in';
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }
        }

        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use((request) => {
    request.headers.Authorization =
        CookieManager.getDataFromLocalStorage('token');
    return request;
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            originalRequest.url === rbacAPI + 'refreshToken'
        ) {
            CookieManager.clearStorage();
            window.location.href = '/sign-in';
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + token;
                        return axiosInstance(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;
            return new Promise(function (resolve, reject) {
                axios
                    .get(rbacAPI + 'refreshToken', {
                        headers: {
                            Authorization:
                                CookieManager.getDataFromLocalStorage('token')
                        }
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            CookieManager.setDataToLocalStorage(
                                'token',
                                res.data.data.token
                            );
                            CookieManager.setDataToLocalStorage(
                                'userInfo',
                                res.data.data.userInfo
                            );
                            processQueue(null, res.data.data.token);
                            resolve(axiosInstance(originalRequest));
                        }
                    })
                    .then((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .catch((err) => {
                        CookieManager.clearStorage();
                        window.location.href = '/sign-in';
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        return Promise.reject(error);
    }
);

export { axiosInstance, rbacAxiosInstance };
