import React, { useState } from 'react';
import { Container, Grid, Button, TextField } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Link, useHistory } from 'react-router-dom';

import { rbacAxiosInstance } from '../../../helpers/axiosInstance';
import { errorToaster, sucessToaster } from '../toaster';
import './styles.css';

const UpdatePassword = () => {
    const [visiblNewPw, setVisiblNewPw] = useState(false);
    const [visiblConfPw, setVisiblConfPw] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [visiblOldPw, setVisiblOldPw] = useState(false);
    const [oldPw, setOldPw] = useState('');
    const [errors, setErrors] = useState({
        minLength: 'err',
        upperCase: 'err',
        lowerCase: 'err',
        splChar: 'err'
    });

    const history = useHistory();

    const newPwClick = () => {
        setVisiblNewPw(!visiblNewPw);
    };

    const oldPwClick = () => {
        setVisiblOldPw(!visiblOldPw);
    };

    const confirmPwClick = () => {
        setVisiblConfPw(!visiblConfPw);
    };
    const newPwChange = (e) => {
        console.log(e.target.value, 'input vall');
        let text = e.target.value;
        setPassword(text);
        // var pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        let errors = {};
        console.log(text.search(/\W/), 'uooerr');
        if (text.length < 12) {
            errors.minLength = 'Your password must be at least 12 characters';
        } else {
            errors.minLength = '';
        }
        if (text.search(/[A-Z]/) < 0) {
            errors.upperCase =
                'Your password must contain at least one upper case.';
        } else {
            errors.upperCase = '';
        }
        if (text.search(/[a-z]/) < 0) {
            errors.lowerCase =
                'Your password must contain at least one lower case.';
        } else {
            errors.lowerCase = '';
        }
        if (text.search(/\W/) < 0) {
            errors.splChar =
                'Your password must contain at least one special charecter.';
        } else {
            errors.splChar = '';
        }
        // let result = pattern.test(text);
        setErrors(errors);
        console.log(errors, 'result');
    };

    const confirmChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const oldPwChange = (e) => {
        setOldPw(e.target.value);
    };

    const submitClick = () => {
        if (!oldPw) {
            errorToaster('Please enter old password!');
        } else if (password !== confirmPassword) {
            errorToaster('password and confirm password miss match');
        } else if (
            errors.minLength ||
            errors.upperCase ||
            errors.lowerCase ||
            errors.splChar
        ) {
            errorToaster('New Password criteria not matched');
        } else {
            changePassword();
        }
    };

    const changePassword = async (params) => {
        params = {
            confirmPassword: confirmPassword,
            oldPassword: oldPw,
            password: password
        };
        console.log(params, 'paramsll');
        const result = await rbacAxiosInstance
            .post('changePassword', params)
            .then((data) => {
                console.log(data, 'data');
                if (data.data.status == 200) {
                    history.push('/sign-in');
                    sucessToaster('Sucess!');
                }
            })
            .catch((err) => {
                let errMsg = err?.response?.data?.message;
                errorToaster(errMsg);
                return err.response.data;
            });
        return result;
    };

    console.log(visiblNewPw, 'visiblNewPw');
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item md={4}></Grid>
                    <Grid item md={5} sm={12} xs={12}>
                        <h1 className="profile-head-text">Update Password</h1>
                        <small className="margin-top">
                            In order to protect your account, make sure your
                            <br /> password:
                        </small>
                        <br />
                        <br />
                        <CheckCircleIcon
                            className={errors.minLength ? '' : 'color-green'}
                        />
                        <small className="check-text">
                            Min length 12 characters
                        </small>
                        <br />
                        <CheckCircleIcon
                            className={errors.upperCase ? '' : 'color-green'}
                        />
                        <small className="check-text">
                            At least one upper case character A-Z
                        </small>
                        <br />
                        <CheckCircleIcon
                            className={errors.lowerCase ? '' : 'color-green'}
                        />
                        <small className="check-text">
                            At least one lower case character a-z
                        </small>
                        <br />
                        <CheckCircleIcon
                            className={errors.splChar ? '' : 'color-green'}
                        />
                        <small className="check-text">
                            At least one special character !@#$%^&*()-_=+,.;'[]
                        </small>
                        <br />
                        <br />
                        <small className="margin-top-small">Old password</small>
                        <br />
                        <p className="input-pw-section">
                            <TextField
                                id="outlined-basic"
                                onChange={oldPwChange}
                                label="Enter old password"
                                type={visiblOldPw ? 'text' : 'password'}
                                variant="outlined"
                                className="pw-input"
                            />
                            {visiblOldPw ? (
                                <VisibilityIcon
                                    className="eye-icon"
                                    onClick={oldPwClick}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    onClick={oldPwClick}
                                    className="eye-icon"
                                />
                            )}
                            {/* <VisibilityIcon className='eye-icon'/>  */}
                        </p>
                        <small className="margin-top-small">new password</small>
                        <p className="input-pw-section">
                            <TextField
                                id="outlined-basic"
                                onChange={newPwChange}
                                label="Enter new password"
                                type={visiblNewPw ? 'text' : 'password'}
                                variant="outlined"
                                className="pw-input"
                            />
                            {visiblNewPw ? (
                                <VisibilityIcon
                                    className="eye-icon"
                                    onClick={newPwClick}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    onClick={newPwClick}
                                    className="eye-icon"
                                />
                            )}
                            {/* <VisibilityIcon className='eye-icon'/>  */}
                        </p>
                        <br />
                        <small className="margin-top-small">
                            Confirm your password
                        </small>
                        <br />
                        <p className="input-pw-section">
                            <TextField
                                id="outlined-basic"
                                label="Enter confirm password"
                                type={visiblConfPw ? 'text' : 'password'}
                                variant="outlined"
                                className="pw-input"
                                onChange={confirmChange}
                            />
                            {visiblConfPw ? (
                                <VisibilityIcon
                                    className="eye-icon"
                                    onClick={confirmPwClick}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    onClick={confirmPwClick}
                                    className="eye-icon"
                                />
                            )}
                        </p>
                        <br />
                        <div className="buttons-part">
                            <Button
                                size="small"
                                onClick={submitClick}
                                className="margin-top submit-btn"
                            >
                                SUBMIT
                            </Button>
                            <Link to="/myprofile">
                                <Button
                                    size="small"
                                    className="margin-top cancel-btn"
                                >
                                    CANCEL
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                    <Grid item md={3}></Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default UpdatePassword;
