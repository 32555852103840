import React, { useState, useEffect } from 'react';

import { Container, Grid, Button, Avatar, CircularProgress } from '@mui/material';

import { Link } from 'react-router-dom';
import { rbacAxiosInstance } from '../../../helpers/axiosInstance';

import './styles.css';

const Profile = () => {
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        setLoading(true);
        const result = await rbacAxiosInstance
            .post('retrieveCurrentUserDetails')
            .then((data) => {
                data?.data ? setUserInfo(data.data.data) : setUserInfo({});
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                return err.response.data;
            });
        return result;
    };

    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <Container maxWidth="md">
                    <div className="profile-header">
                        <h1 className="profile-head-text">Profile</h1>
                        <Avatar
                            src={
                                userInfo.photo
                                    ? userInfo.photo
                                    : '/broken-image.jpg'
                            }
                            className="avatar margin-top"
                        />
                    </div>
                    <br />
                    {loading && <CircularProgress />}
                    <Grid container spacing={3}>
                        <Grid item md={4}></Grid>
                        <Grid item md={5} sm={12} xs={12}>
                            <p className="margin-top">Name</p>
                            <b>
                                {userInfo.firstName + ' ' + userInfo.lastName}
                            </b>
                            <br />
                            <p className="margin-top">Email</p>
                            <b>{userInfo.email}</b>
                            <br />
                            <p className="margin-top">Contact</p>
                            <b>{userInfo.phone}</b>
                            <br />
                            <br />
                            <Link to="/updatePassword">
                                <Button
                                    size="small"
                                    className="margin-top update-pw-btn"
                                    variant="outlined"
                                >
                                    UPDATE PASSWORD
                                </Button>
                            </Link>
                            <br />
                            <br />
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default Profile;
