import React, { useState, useEffect } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import validate from 'validate.js';
import { makeStyles } from '@mui/styles';
import {
    Typography,
    Link,
    Grid,
    TextField,
    Button,
    Dialog,
    DialogContent
} from '@mui/material';
import AlertIcon from './images/icon-cm-alert.svg';
import { axiosInstance, rbacAxiosInstance } from 'helpers';

const sectionSidePadding = 30;
const sectionSidePaddingSmUp = 38;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.white
    },

    rootInner: {
        backgroundColor: theme.palette.white
    },

    header: {
        padding: `30px ${sectionSidePadding}px`,
        backgroundColor: '#f8f8f8',

        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(5)}px ${sectionSidePaddingSmUp}px`
        }
    },

    backLink: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            display: 'block',
            fontSize: 14
        }
    },

    section: {
        padding: '30px 38px',
        borderTop: '1px solid #dfdfdf',

        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5)
        }
    },

    firstSection: {
        borderTop: 0
    },

    sectionBlock: {
        marginTop: theme.spacing(5)
    },

    sectionHeader: {
        display: 'flex',
        alignItems: 'flex-end'
    },

    sectionHeaderHeading: {
        fontSize: 20,
        letterSpacing: 0.15,
        lineHeight: 1,
        color: theme.palette.primary.main,

        '&:after': {
            content: '""',
            display: 'block',
            width: 50,
            height: 5,
            marginTop: theme.spacing(1),
            backgroundColor: theme.palette.primary.main
        }
    },

    sectionHeaderLabel: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingBottom: 14,
            display: 'block'
        }
    },

    sectionCenterContent: {
        padding: theme.spacing(5, 0),
        textAlign: 'center'
    },

    deviceInfoContent: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            marginTop: theme.spacing(2)
        }
    },

    button: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(1)
    },

    cancelButton: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(1),
        backgroundColor: '#AEAEAE',
        color: '#ffffff'
    },

    confirmButton: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: theme.spacing(1)
    },

    confirmCancelButton: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: theme.spacing(1),
        backgroundColor: '#AEAEAE',
        color: '#ffffff'
    },
    alertIcon: {
        width: 65,
        height: 65,
        [theme.breakpoints.up('sm')]: {
            marginRight: '20px',
            marginTop: '10px'
        }
    },
    confirmText: {
        marginTop: '25px',
        marginBottom: '25px',

        [theme.breakpoints.up('sm')]: {
            marginLeft: '5px',
            marginRight: '5px'
        },

        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
            marginBottom: '10px'
        }
    }
}));

const schema = {
    customersDeviceName: {
        presence: { allowEmpty: false },
        exclusion: {
            within: ['All Router'],
            message: "'%{value}' is not allowed"
        }
    }
};

const EditRouter = (props) => {
    const hostIP = props.match.params.hostIP;

    const { promiseInProgress } = usePromiseTracker();

    const classes = useStyles();

    const [routerName, setRouterName] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [formState, setFormState] = useState({
        values: {
            name: '',
            customersDeviceName: '',
            hostIP: hostIP
        },
        isValid: false,
        touched: {},
        errors: {}
    });
    const [refresh, setRefresh] = useState(false);
    const hasError = (field) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    useEffect(() => {
        trackPromise(
            axiosInstance
                .get(`/getRouter/${hostIP}`)
                .then((data) => {
                    if (data.data.data.length > 0) {
                        setFormState((formState) => ({
                            ...formState,
                            values: data.data.data[0]
                        }));
                        setRouterName(
                            data.data.data[0].customersDeviceName || ''
                        );
                    } else {
                        props.history.push('/routerDetails');
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        );
    }, [refresh]);

    useEffect(() => {
        trackPromise(
            rbacAxiosInstance
                .post('checkIfUserHasAccess', {
                    system: 'MIN',
                    module: 'ADMIN',
                    access: 'Update'
                })
                .then((data) => {
                    setHasAccess(true);
                })
                .catch((err) => {
                    setHasAccess(false);
                    console.log(err);
                })
        );
    }, []);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleFormInputChange = (e) => {
        e.persist();

        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [e.target.name]:
                    e.target.type === 'checkbox'
                        ? e.target.checked
                        : e.target.value
            },
            touched: {
                ...formState.touched,
                [e.target.name]: true
            }
        }));
    };

    const handleConfirmSubmit = (e) => {
        e.preventDefault();

        trackPromise(
            axiosInstance
                .post(`/updateRouter/${hostIP}`, formState.values)
                .then((data) => {
                    setRefresh((prevState) => !prevState);
                })
                .catch((err) => {
                    console.log(err);
                })
        );

        handleCloseDialog();
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (formState.isValid) {
            handleOpenDialog();
        } else {
            // If still invalid, make the form errors visible.
            const errors = validate(formState.values, schema);
            let newTouched = {};

            Object.keys(formState.values).forEach((name) => {
                newTouched[name] = true;
            });

            setFormState((formState) => ({
                ...formState,
                isValid: errors ? false : true,
                errors: errors || {},
                touched: {
                    ...formState.touched,
                    ...newTouched
                }
            }));
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.backLink}>
                    <Link href="/dashboard" color="inherit">
                        Home
                    </Link>
                    &nbsp; &gt; &nbsp;
                    <Link href="/routerDetails" color="inherit">
                        Router Details
                    </Link>
                    &nbsp; &gt; &nbsp;<b>Edit Router</b>
                </div>

                <div className={classes.sectionHeader}>
                    <Typography variant="h2" component="h1">
                        {routerName}
                    </Typography>
                </div>
            </div>

            <div className={classes.rootInner}>
                <form className={classes.filters} onSubmit={handleFormSubmit}>
                    <div
                        className={`${classes.section} ${classes.firstSection}`}
                    >
                        <div className={classes.sectionHeader}>
                            <Typography
                                variant="h1"
                                className={classes.sectionHeaderHeading}
                            >
                                Device Info
                            </Typography>
                        </div>

                        <div className={classes.deviceInfoContent}>
                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Router Name:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="name"
                                        type="text"
                                        disabled
                                        value={formState.values.name}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Name:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="customersDeviceName"
                                        id="customersDeviceName"
                                        type="text"
                                        value={
                                            formState.values
                                                .customersDeviceName || ''
                                        }
                                        error={hasError('customersDeviceName')}
                                        helperText={
                                            hasError('customersDeviceName')
                                                ? formState.errors
                                                      .customersDeviceName[0]
                                                : null
                                        }
                                        disabled={!hasAccess}
                                        onChange={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        MAC Address:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="MACAddress"
                                        type="text"
                                        value={
                                            formState.values.MACAddress || ''
                                        }
                                        disabled={!hasAccess}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Operational Status:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="operationalStatus"
                                        type="text"
                                        value={formState.values.status || ''}
                                        disabled
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Operational Since:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="operationalSince"
                                        type="text"
                                        value={
                                            formState.values.operationalSince ||
                                            ''
                                        }
                                        disabled={!hasAccess}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Device Type:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="type"
                                        type="text"
                                        value={formState.values.type || ''}
                                        disabled={!hasAccess}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Model:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="model"
                                        type="text"
                                        value={formState.values.model || ''}
                                        disabled={!hasAccess}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div className={classes.devicesSectionHeader}>
                            <Typography
                                variant="h1"
                                className={`${classes.sectionHeaderHeading} ${classes.devicesSectionHeaderHeading}`}
                            >
                                Device Location
                            </Typography>
                        </div>

                        <div className={classes.deviceInfoContent}>
                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Site Name:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="siteName"
                                        type="text"
                                        value={formState.values.siteName || ''}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Address:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="address"
                                        type="text"
                                        value={formState.values.address || ''}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        City:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="city"
                                        type="text"
                                        value={formState.values.city || ''}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        State:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="state"
                                        type="text"
                                        value={formState.values.state || ''}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Country:
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        name="country"
                                        type="text"
                                        value={formState.values.country || ''}
                                        onInput={handleFormInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div>
                            <Grid
                                container
                                justify="center"
                                align="center"
                                style={{
                                    paddingBottom: '20px',
                                    paddingTop: '20px'
                                }}
                            >
                                <Button
                                    type="submit"
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classes.cancelButton}
                                    variant="contained"
                                    onClick={() =>
                                        (window.location.href =
                                            '/routerDetails')
                                    }
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </div>
                    </div>

                    <Dialog
                        open={openDialog}
                        fullWidth
                        onClose={handleCloseDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                align="center"
                                style={{
                                    paddingBottom: '20px',
                                    paddingTop: '20px'
                                }}
                            >
                                <img
                                    src={AlertIcon}
                                    alt=""
                                    className={classes.alertIcon}
                                />
                                <Typography
                                    variant="h2"
                                    className={classes.confirmText}
                                >
                                    Confirm Changes?
                                </Typography>
                            </Grid>
                        </DialogContent>
                        <Grid
                            container
                            justify="center"
                            align="center"
                            style={{
                                paddingBottom: '20px',
                                paddingTop: '10px'
                            }}
                        >
                            <Button
                                onClick={handleConfirmSubmit}
                                className={classes.confirmButton}
                                variant="contained"
                                color="primary"
                            >
                                Confirm
                            </Button>
                            <Button
                                onClick={handleCloseDialog}
                                className={classes.confirmCancelButton}
                                variant="contained"
                                onClick={() => (window.location.href = '/')}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Dialog>
                </form>
            </div>
        </div>
    );
};

export default EditRouter;
