// import React from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// import { Line } from 'react-chartjs-2';
// // import {faker} from 'faker';
// import  {faker} from '@faker-js/faker';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Line Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.random.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.random.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

// export default function App() {
//   return <Line options={options} data={data} />;
// }
import React from 'react';
// import "./styles.css";

import { Line } from 'react-chartjs-2';

const data = {
    labels: ['Jan 20', 'Jan 21'],
    datasets: [
        {
            label: 'RX MB',
            data: [],
            fill: false,
            borderColor: 'blue'
        },
        {
            label: 'TX MB',
            data: [],
            fill: false,
            borderColor: '#742774'
        }
    ]
};

export default function Chart({ chartData }) {
    let inputData = chartData ? chartData : data;
    return <Line data={inputData} />;
}
