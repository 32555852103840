import React, { useState, useEffect } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { makeStyles } from '@mui/styles';
import {
    Link,
    Typography,
    CircularProgress,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TableSortLabel,
    TextField,
    InputAdornment,
    IconButton,
    Checkbox,
    FormControlLabel,
    Grid,
    Dialog,
    DialogContent
} from '@mui/material';
import ArrowLeftIcon from './components/ArrowLeftIcon';
import ArrowRightIcon from './components/ArrowRightIcon';
import AlertIcon from './images/icon-cm-alert.svg';
/*import AddIcon from './components/AddIcon';
import DeleteIcon from './components/DeleteIcon';*/
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from './components/SearchIcon';
import RouterImage from './images/icon-router.svg';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
//import SpeedDial from '@material-ui/lab/SpeedDial';
//import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
//import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { axiosInstance, rbacAxiosInstance } from '../../helpers/axiosInstance';

//const resultsGridCustomBreakpointWidth = '(max-width: 480px)';
//const resultsGridCustomBreakpoint = `@media ${resultsGridCustomBreakpointWidth}`;
//const graphHeightCustomBreakpoint = '@media (min-height: 920px)';

const sectionSidePadding = 30;
const sectionSidePaddingSmUp = 38;
const pageSize = 15;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white
    },

    section: {
        padding: `30px ${sectionSidePadding}px`,
        backgroundColor: '#f8f8f8',

        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(5)}px ${sectionSidePaddingSmUp}px`
        }
    },

    altSection: {
        backgroundColor: theme.palette.white
    },

    altSectionFlex: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    sectionCenterContent: {
        textAlign: 'center'
    },

    backLink: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            display: 'block',
            fontSize: 14
        }
    },
    table: {
        marginTop: theme.spacing(5)
    },

    tableCell: {
        width: '25%'
    },

    pagination: {
        marginRight: -theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    },

    paginationButton: {
        width: 35,
        height: 35,
        minWidth: 35,
        padding: '3px 8px',
        marginRight: theme.spacing(1)
    },
    icon: {
        color: 'transparent'
    },
    routerImage: {
        width: 140,
        height: 140
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    devicesSectionHeader: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },

    devicesSectionHeaderHeading: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            paddingRight: theme.spacing(5)
        }
    },

    devicesSectionActions: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        marginLeft: -theme.spacing(2),
        marginBottom: theme.spacing(5),

        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            maxWidth: 800,
            marginTop: 0,
            marginLeft: 0
        }
    },

    devicesSearchField: {
        width: 'calc(100% - 100px)',
        paddingLeft: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 100px)'
        },

        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 150px)'
        }
    },

    devicesSearchCta: {
        width: 100,
        paddingLeft: theme.spacing(2),
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            width: 150
        }
    },

    devicesActions: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        marginLeft: -theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            maxWidth: 800,
            marginTop: 0,
            marginLeft: 0
        }
    },

    CRUD: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            width: 240,
            marginTop: 0
        },

        [theme.breakpoints.up('md')]: {
            width: 270,
            paddingLeft: theme.spacing(5)
        }
    },

    CRUDButton: {
        fontSize: '19px'
    },

    button: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(1)
    },

    cancelButton: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(1),
        backgroundColor: '#AEAEAE',
        color: '#ffffff'
    },

    confirmButton: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: theme.spacing(1)
    },

    confirmCancelButton: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        margin: theme.spacing(1),
        backgroundColor: '#AEAEAE',
        color: '#ffffff'
    },
    alertIcon: {
        width: 75,
        height: 75
    },
    confirmText: {
        marginTop: '25px',
        marginBottom: '25px',

        [theme.breakpoints.up('sm')]: {
            marginLeft: '5px',
            marginRight: '5px'
        },

        [theme.breakpoints.down('xs')]: {
            marginTop: '10px',
            marginBottom: '10px'
        }
    },

    speedDial: {
        position: 'fixed',
        right: 20,
        bottom: 30
    },

    statusGreen: {
        color: theme.palette.primary.main
    },
    statusRed: {
        color: theme.palette.error.main
    }
}));

const RouterDetails = (props) => {
    const classes = useStyles();

    const { promiseInProgress } = usePromiseTracker();

    const useAltSectionFlexClass = () => {
        return promiseInProgress === 0;
    };

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [deviceRouterData, setDeviceRouterData] = useState();
    const [order, setOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const [searchDisplay, setSearchDisplay] = useState('');
    const [hasAccess, setHasAccess] = useState(false);
    const [deleteSelected, setDeleteSelected] = useState(false);
    const [routerSelectedForDeletion, setRouterSelectedForDeletion] = useState(
        []
    );
    const [openDialog, setOpenDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);
    //const [open, setOpen] = React.useState(false);

    useEffect(() => {
        trackPromise(
            axiosInstance
                .get('getAllRouters')
                .then(updateDeviceRouterData)
                .catch((error) => {
                    console.log(error);
                })
        );
    }, [refresh]);

    useEffect(() => {
        trackPromise(
            rbacAxiosInstance
                .post('checkIfUserHasAccess', {
                    system: 'MIN',
                    module: 'ADMIN',
                    access: 'Delete'
                })
                .then((data) => {
                    setHasAccess(true);
                })
                .catch((err) => {
                    setHasAccess(false);
                    console.log(err);
                })
        );
    }, [refresh]);

    const updateDeviceRouterData = (response) => {
        setDeviceRouterData(response.data.data.result);
        setCurrentPage(0);
        setTotalPages(response.data.data.totalRecord / pageSize);
    };

    const handlePaginationButtonClick = (index) => {
        setCurrentPage(index);
    };

    const handlePaginationPreviousButtonClick = () => {
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handlePaginationNextButtonClick = () => {
        setCurrentPage(Math.min(totalPages - 1, currentPage + 1));
    };

    const handleSortTable = () => {
        let isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleDevicesSearchFieldInput = (e) => {
        setSearchDisplay(e.target.value);
    };

    const handleDevicesSearchFieldKeyPress = (e) => {
        if (
            (typeof e.key !== undefined && e.key === 'Enter') ||
            (typeof e.keyCode !== undefined && e.keyCode === 13)
        ) {
            setSearch(searchDisplay);
        }
    };

    const handleDevicesSearchFieldReset = (e) => {
        setSearchDisplay('');
        setSearch('');
    };

    const handleDevicesSearchCtaClick = () => {
        setSearch(searchDisplay);
    };

    /*const handleOnClickCreateRouterButton = () => {
        props.history.push('/addRouter');
    }

    const handleOnClickDeleteRouterButton = () => {
        setDeleteSelected(true);
    }*/

    const handleCancelDeleteButton = () => {
        setDeleteSelected(false);
    };

    const handleCheckBoxDelete = (e) => {
        let checked = routerSelectedForDeletion;
        let index = checked.indexOf(e.target.value);
        if (index >= 0) {
            checked.splice(index, 1);
            setRouterSelectedForDeletion(checked);
        } else {
            checked.push(e.target.value);
            setRouterSelectedForDeletion(checked);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmSubmit = () => {
        axiosInstance
            .post('deleteRouter', { hostIPList: routerSelectedForDeletion })
            .then((data) => {
                setRefresh(true);
            })
            .catch((err) => {
                console.log(err);
            });
        handleCloseDialog();
        setRefresh((prevState) => !prevState);
    };

    /*const handleCloseSpeedDial = () => {
        setOpen(false);
    };
    
    const handleOpenSpeedDial = () => {
        setOpen(true);
    };

    const actions = [
        { icon: <AddIcon />, name: 'Create Router', onClick:handleOnClickCreateRouterButton },
        { icon: <DeleteIcon />, name: 'Delete Router', onClick:handleOnClickDeleteRouterButton },
    ];*/

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.backLink}>
                    <Link href="./dashboard" color="inherit">
                        Home
                    </Link>
                    &nbsp; &gt; <b>Router Details </b>
                </div>

                <div className={classes.sectionHeader}>
                    <div className={classes.devicesSectionHeader}>
                        <Typography
                            variant="h2"
                            className={`${classes.sectionHeaderHeading} ${classes.devicesSectionHeaderHeading}`}
                            component="h1"
                        >
                            Router Details
                        </Typography>
                    </div>
                </div>
            </div>

            <div
                className={`${classes.section} ${classes.altSection} ${useAltSectionFlexClass() ? classes.altSectionFlex : ''}`}
            >
                {promiseInProgress && (
                    <div className={classes.sectionCenterContent}>
                        <CircularProgress />
                    </div>
                )}

                {!promiseInProgress &&
                    deviceRouterData &&
                    deviceRouterData.length > 0 && (
                        <>
                            <div className={classes.devicesSectionActions}>
                                <div className={classes.devicesSearchField}>
                                    <TextField
                                        fullWidth
                                        placeholder="Search by name"
                                        name="deviceName"
                                        type="text"
                                        id="deviceName"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {search && (
                                                        <IconButton
                                                            aria-label="reset"
                                                            edge="end"
                                                            onClick={
                                                                handleDevicesSearchFieldReset
                                                            }
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}

                                                    {!search && (
                                                        <IconButton
                                                            aria-label="search"
                                                            edge="end"
                                                            onClick={
                                                                handleDevicesSearchCtaClick
                                                            }
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            )
                                        }}
                                        value={searchDisplay}
                                        onInput={handleDevicesSearchFieldInput}
                                        onKeyPress={
                                            handleDevicesSearchFieldKeyPress
                                        }
                                    />
                                </div>

                                <div className={classes.devicesSearchCta}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        onClick={handleDevicesSearchCtaClick}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                            {(search === '' ||
                                search === null ||
                                search === undefined ||
                                deviceRouterData.filter((row) =>
                                    search !== null &&
                                    search !== undefined &&
                                    search !== ''
                                        ? (row.name
                                              ? row.name.toUpperCase()
                                              : ''
                                          ).includes(search.toUpperCase()) ||
                                          (row.siteName
                                              ? row.siteName.toUpperCase()
                                              : ''
                                          ).includes(search.toUpperCase())
                                        : true
                                ).length > 0) && (
                                <TableContainer
                                    className={classes.table}
                                    id="table"
                                >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {deleteSelected && (
                                                    <TableCell
                                                        style={{ width: '5%' }}
                                                    />
                                                )}
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    sortDirection={order}
                                                >
                                                    <TableSortLabel
                                                        active={true}
                                                        direction={order}
                                                        onClick={
                                                            handleSortTable
                                                        }
                                                    >
                                                        Device Name
                                                        <span
                                                            className={
                                                                classes.visuallyHidden
                                                            }
                                                        >
                                                            {order === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </span>
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Device Type
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Device Location
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    Operational Status
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {stableSort(
                                                deviceRouterData,
                                                getComparator(order, 'name')
                                            )
                                                .filter((row) =>
                                                    search !== null &&
                                                    search !== undefined &&
                                                    search !== ''
                                                        ? (row.name
                                                              ? row.name.toUpperCase()
                                                              : ''
                                                          ).includes(
                                                              search.toUpperCase()
                                                          ) ||
                                                          (row.siteName
                                                              ? row.siteName.toUpperCase()
                                                              : ''
                                                          ).includes(
                                                              search.toUpperCase()
                                                          )
                                                        : true
                                                )
                                                .slice(
                                                    currentPage * pageSize,
                                                    (currentPage + 1) * pageSize
                                                )
                                                .map((row, index) => (
                                                    <TableRow key={index}>
                                                        {deleteSelected && (
                                                            <TableCell
                                                                style={{
                                                                    width: '5%'
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            icon={
                                                                                <RadioButtonUncheckedIcon />
                                                                            }
                                                                            checkedIcon={
                                                                                <RadioButtonCheckedIcon />
                                                                            }
                                                                            value={
                                                                                row.host
                                                                            }
                                                                            onChange={
                                                                                handleCheckBoxDelete
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </TableCell>
                                                        )}
                                                        <TableCell>
                                                            <Link
                                                                href={`/editRouter/${row.hostIP}`}
                                                            >
                                                                {
                                                                    row.customersDeviceName
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            Router
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.siteName}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div
                                                                className={
                                                                    row.status ===
                                                                    'Up'
                                                                        ? classes.statusGreen
                                                                        : classes.statusRed
                                                                }
                                                            >
                                                                {row.status}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    Showing{' '}
                                                    {currentPage * pageSize + 1}
                                                    -
                                                    {Math.min(
                                                        (currentPage + 1) *
                                                            pageSize,
                                                        deviceRouterData.length
                                                    )}{' '}
                                                    out of{' '}
                                                    {deviceRouterData.length}
                                                </TableCell>

                                                <TableCell colSpan="2">
                                                    {totalPages > 1 && (
                                                        <div
                                                            className={
                                                                classes.pagination
                                                            }
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                size="large"
                                                                className={
                                                                    classes.paginationButton
                                                                }
                                                                onClick={
                                                                    handlePaginationPreviousButtonClick
                                                                }
                                                            >
                                                                <ArrowLeftIcon
                                                                    className={
                                                                        classes.icon
                                                                    }
                                                                />
                                                            </Button>

                                                            {Array(totalPages)
                                                                .fill(undefined)
                                                                .map(
                                                                    (
                                                                        val,
                                                                        index
                                                                    ) => (
                                                                        <Button
                                                                            variant={
                                                                                index ===
                                                                                currentPage
                                                                                    ? 'contained'
                                                                                    : 'outlined'
                                                                            }
                                                                            color={
                                                                                index ===
                                                                                currentPage
                                                                                    ? 'primary'
                                                                                    : undefined
                                                                            }
                                                                            size="large"
                                                                            className={
                                                                                classes.paginationButton
                                                                            }
                                                                            onClick={() => {
                                                                                handlePaginationButtonClick(
                                                                                    index
                                                                                );
                                                                            }}
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {index +
                                                                                1}
                                                                        </Button>
                                                                    )
                                                                )}

                                                            <Button
                                                                variant="outlined"
                                                                size="large"
                                                                className={`${classes.paginationButton} ${classes.paginationIconButton}`}
                                                                onClick={
                                                                    handlePaginationNextButtonClick
                                                                }
                                                            >
                                                                <ArrowRightIcon
                                                                    className={
                                                                        classes.icon
                                                                    }
                                                                />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            )}
                        </>
                    )}

                {!promiseInProgress &&
                    deviceRouterData &&
                    deviceRouterData.length === 0 && (
                        <div className={classes.sectionCenterContent}>
                            <img
                                src={RouterImage}
                                alt=""
                                className={classes.routerImage}
                            />

                            <Typography variant="body1">
                                No Router data available.
                            </Typography>
                        </div>
                    )}

                {search &&
                    deviceRouterData.filter((row) =>
                        search !== null && search !== undefined && search !== ''
                            ? (row.name ? row.name.toUpperCase() : '').includes(
                                  search.toUpperCase()
                              ) ||
                              (row.siteName
                                  ? row.siteName.toUpperCase()
                                  : ''
                              ).includes(search.toUpperCase())
                            : true
                    ).length <= 0 && (
                        <div className={classes.sectionCenterContent}>
                            <img
                                src={RouterImage}
                                alt=""
                                className={classes.routerImage}
                            />

                            <Typography variant="body1">
                                No Devices Found.
                            </Typography>
                        </div>
                    )}

                {deleteSelected &&
                    (!search ||
                        (search &&
                            deviceRouterData.filter((row) =>
                                search !== null &&
                                search !== undefined &&
                                search !== ''
                                    ? (row.name
                                          ? row.name.toUpperCase()
                                          : ''
                                      ).includes(search.toUpperCase()) ||
                                      (row.siteName
                                          ? row.siteName.toUpperCase()
                                          : ''
                                      ).includes(search.toUpperCase())
                                    : true
                            ).length <= 0)) && (
                        <div>
                            <Grid
                                container
                                justify="center"
                                align="center"
                                style={{
                                    paddingBottom: '20px',
                                    paddingTop: '20px'
                                }}
                            >
                                <Button
                                    onClick={handleOpenDialog}
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                >
                                    Delete
                                </Button>
                                <Button
                                    className={classes.cancelButton}
                                    variant="contained"
                                    onClick={handleCancelDeleteButton}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </div>
                    )}
            </div>

            <Dialog
                open={openDialog}
                fullWidth
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <Grid
                        container
                        justify="center"
                        align="center"
                        style={{ paddingBottom: '20px', paddingTop: '20px' }}
                    >
                        <img
                            src={AlertIcon}
                            alt=""
                            className={classes.alertIcon}
                        />
                        <Typography
                            variant="h2"
                            className={classes.confirmText}
                        >
                            Confirm Changes?
                        </Typography>
                    </Grid>
                </DialogContent>
                <Grid
                    container
                    justify="center"
                    align="center"
                    style={{ paddingBottom: '20px', paddingTop: '10px' }}
                >
                    <Button
                        onClick={handleConfirmSubmit}
                        className={classes.confirmButton}
                        variant="contained"
                        color="primary"
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={handleCloseDialog}
                        className={classes.confirmCancelButton}
                        variant="contained"
                        onClick={() => (window.location.href = '/')}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Dialog>
        </div>
    );
};

export default RouterDetails;
