import React from 'react';
import { SvgIcon } from '@mui/material';

const TableIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M21.8403 3.19995H1.76025V20.16H21.8403V3.19995Z"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M1.6001 8.31995H21.6001"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M6.56006 3.35999V20.16"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M15.04 3.35999V20.16"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M21.6003 3.35999H1.76025V8.31999H21.6003V3.35999Z"
                fill="#128958"
            />
            <path
                d="M1.6001 12.7999H21.7601"
                stroke="#128958"
                strokeMiterlimit="10"
            />
            <path
                d="M1.6001 16.6399H21.7601"
                stroke="#128958"
                strokeMiterlimit="10"
            />
        </SvgIcon>
    );
};

export default TableIcon;
