import React from 'react';
import { SvgIcon } from '@mui/material';

const ArrowRightIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M7.9998 5.61499L15.4326 12.2271L7.9998 18.3193"
                stroke="#808495"
            />
        </SvgIcon>
    );
};

export default ArrowRightIcon;
