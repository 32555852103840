export default {
    root: {
        '& tr:last-child': {
            '& td': {
                paddingBottom: 0,
                borderBottom: 0
            }
        }
    }
};
