import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        dark: '#306948',
        main: '#128958',
        light: '#8DC63F'
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue['A400'],
        light: colors.blue['A400']
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.teal[400],
        light: colors.blue[400]
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[500],
        light: colors.orange[400]
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: '#F60000',
        light: colors.red[400]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    background: {
        default: '#F4F6F8',
        dark: '#DFDFDF',
        paper: white
    },
    chart: {
        line1: colors.green[500],
        line2: colors.red[400],
        line3: colors.red[700],
        line4: colors.red[900],
        line5: colors.red['A700']
    },
    icon: '#004B35',
    divider: colors.grey[200]
};
