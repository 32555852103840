import React from 'react';
import { SvgIcon } from '@mui/material';

const DownloadIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
		M21.5,12.2v5.9c0,0.8-0.2,0.9-0.9,0.9H3.4c-0.8,0-0.9-0.1-0.9-1c-0.1-1.9,0-3.7,0-5.6"
            />
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
        M8.3,12.1l3.7,3.9V5"
            />
            <path
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
        M15.7,11.8L12,15.9"
            />
        </SvgIcon>
    );
};

export default DownloadIcon;
