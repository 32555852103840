import palette from '../palette';
import typography from '../typography';

export default {
    root: {
        ...typography.body1,
        padding: 20,
        borderBottom: `1px solid ${palette.divider}`,

        '&:first-child': {
            paddingLeft: 40
        },

        '&:last-child': {
            paddingRight: 40
        }
    }
};
