import React from 'react';
import { SvgIcon } from '@mui/material';

const ArrowLeftIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M16.0002 5.61499L8.56738 12.2271L16.0002 18.3193"
                stroke="#808495"
            />
        </SvgIcon>
    );
};

export default ArrowLeftIcon;
