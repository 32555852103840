import React from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Box, Link } from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

const sectionSidePadding = 30;
const sectionSidePaddingSmUp = 38;

const useStyles = (theme) => ({
    root: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%'
    },
    section: {
        padding: `30px ${sectionSidePadding}px`,
        backgroundColor: '#f8f8f8',

        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing(5)}px ${sectionSidePaddingSmUp}px`
        }
    },
    devicesSectionHeader: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },

    devicesSectionHeaderHeading: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            paddingRight: theme.spacing(5)
        }
    },
    backLink: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
            display: 'block',
            fontSize: 14
        }
    },
    header: {
        backgroundColor: '#f4f6f8',
        padding: '20px 0',
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    body: {
        backgroundColor: 'white',
        width: '100%',
        padding: '50px 0',
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    greenText: {
        color: theme.palette.primary.main,
        paddingBottom: '10px'
    },
    greenUnderText: {
        width: '50px',
        height: '6px',
        backgroundColor: theme.palette.primary.main
    },
    CTAHeader: {
        display: 'flex',
        flexDirection: 'column'
    },
    icon: {
        paddingTop: '7px'
    },
    button: {
        padding: '0px 15px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '5px'
        }
    },
    buttonText: {
        paddingLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    file: {
        width: '700px',
        marginTop: '20px',
        padding: '20px',
        border: 'solid 1.5px #f4f6f8',
        borderRadius: '7px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    fileContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '10px'
    },
    fileName: {
        width: '70%'
    },
    contact: {
        display: 'flex',
        alignItems: 'center'
    },
    contactIcon: {
        height: '40px',
        width: '40px',
        marginRight: '20px'
    }
});

class Support extends React.Component {
    constructor() {
        super();
        this.state = {
            userGuideFileName: 'MIN Dashboard User Guide 2020',
            userGuideDownloadLink:
                'https://bme1fq.bn.files.1drv.com/y4md8ftGXdbqgbaj6Rg8nMvgdwrKh_f7woAyDeT9xVf03gw0EpVeMHbWw0cVgLlitJoufEOiK-r6k-91n7b-K-juBwMycRLTvD-Hrnl8kLaoekdQO2m2BkQp_pEj9ZRfknvZbW50lVXEZ5Im4VIQoawlCoEuNNP9tagegqVAdOthAbqyj8VLxHiY9MbCqoftE0f1Nu9cAlzUQZFktokkqkiQw'
        };
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <div className={classes.backLink}>
                        <Link href="./dashboard" color="inherit">
                            Home
                        </Link>
                        &nbsp; &gt; <b>Support </b>
                    </div>

                    <div className={classes.sectionHeader}>
                        <div className={classes.devicesSectionHeader}>
                            <Typography
                                variant="h2"
                                className={`${classes.sectionHeaderHeading} ${classes.devicesSectionHeaderHeading}`}
                                component="h1"
                            >
                                Support
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className={classes.body}>
                    <div className={classes.CTAHeader}>
                        <Typography variant="h3">
                            <Box
                                fontWeight="fontWeightRegular"
                                className={classes.greenText}
                            >
                                Get In Touch With Us
                            </Box>
                        </Typography>
                        <div className={classes.greenUnderText}></div>
                    </div>

                    <Typography style={{ padding: '20px 0px' }}>
                        Please call or email us if you have any questions,
                        issues or concerns.
                    </Typography>
                    <div className={classes.contact}>
                        <CallOutlinedIcon
                            color="primary"
                            className={classes.contactIcon}
                        />
                        <Typography className={classes.greenText} variant="h4">
                            <Box fontWeight="fontWeight500">
                                <a
                                    href="tel:1800-82-1919"
                                    style={{ color: 'inherit' }}
                                >
                                    1800-82-1919
                                </a>
                            </Box>
                        </Typography>
                    </div>
                    <div className={classes.contact}>
                        <MailOutlineOutlinedIcon
                            color="primary"
                            className={classes.contactIcon}
                        />
                        <Typography className={classes.greenText} variant="h4">
                            <Box fontWeight="fontWeight500">
                                <a
                                    href="mailto:bizcare@maxis.com.my"
                                    style={{ color: 'inherit' }}
                                >
                                    bizcare@maxis.com.my
                                </a>
                            </Box>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Support);
